import React from "react";
import AuthProvider from "./AuthProvider";
import NotificationProvider from "./NotificationProvider";
import { MediaProvider } from "./Media";
import ThemeProvider from "./ThemeProvider";
import { SidebarProvider } from "./Sidebar";

export default function ContextProviders({ children }) {
  return (
    <NotificationProvider>
      <MediaProvider>
        <AuthProvider>
          <ThemeProvider>
            <SidebarProvider>
              {/* <ReduxProvider store={store}> */}
              {children}
            </SidebarProvider>
            {/* </ReduxProvider> */}
          </ThemeProvider>
        </AuthProvider>
      </MediaProvider>
    </NotificationProvider>
  );
}
