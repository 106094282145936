import React, { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import loadingGif from "../../images/loading.gif";

export default function Loading() {
  // state
  const [count, setCount] = useState(3);

  //hook
  const navigate = useNavigate();

  useEffect(() => {
    const interval = setInterval(() => {
      setCount((currentCount) => --currentCount);
    }, 1000);
    //redirect once is equal to 0
    count === 0 && navigate("/auth/signin");
    // cleanup
    return () => clearInterval(interval);
  }, [count]);

  return (
    <div
      className="flex justify-center items-center max-w-full"
      style={{ height: "90vh" }}
    >
      <img src={loadingGif} alt="Loading..." style={{ width: "400px" }} />
    </div>
  );
}
