import React, { useEffect, useState } from "react";
import { getDepositHistory } from "../../../api/marketer/marketer";
import { formatCurrency, getCurrencyIcon } from "../../../utils/helper";
import Title from "../../form/Title";
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableContainer,
  Paper,
  TextField,
  TablePagination,
} from "@mui/material";
import { Badge } from "@windmill/react-ui";
import { useAuth } from "../../hooks";

const DepositHistory = () => {
  const [depositHistory, setDepositHistory] = useState([]);
  const [currencyIcon, setCurrencyIcon] = useState("");
  const [currencyCode, setCurrencyCode] = useState("");

  const [searchTerm, setSearchTerm] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const { authInfo } = useAuth();

  const fetchDepositHistory = async () => {
    try {
      const res = await getDepositHistory();
      setDepositHistory(res?.data);
    } catch (error) {
      console.error("Failed to fetch deposit history", error);
    }
  };

  const fetchCurrencyIcon = async () => {
    try {
      const data = await getCurrencyIcon();
      setCurrencyIcon(data?.currencyIcon);
      setCurrencyCode(data?.currencyCode);
    } catch (error) {
      console.error("Error fetching currency icon:", error);
    }
  };

  useEffect(() => {
    fetchDepositHistory();
  }, []);
  useEffect(() => {
    fetchCurrencyIcon();
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event?.target?.value, 10));
    setPage(0);
  };

  const filteredDatas = depositHistory?.filter((dat) =>
    dat?.transactionId?.toLowerCase()?.includes(searchTerm?.toLowerCase())
  );
  const paginatedDatas = filteredDatas?.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  return (
    <section className="max-h-screen h-full !w-full overflow-y-auto siderScroll custom-scroll-bar bg-white dark:!bg-[#182235]">
      <div className="mx-3 mt-20">
        <Title>Transaction History</Title>
        <TextField
          label="Search (transaction ID)"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="!bg-white"
        />
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Acc/Name</TableCell>
                <TableCell>Amount</TableCell>
                <TableCell>TransactionID</TableCell>
                <TableCell>Type</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Date</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {paginatedDatas?.map((transaction, index) => {
                return (
                  <TableRow key={index}>
                    <TableCell>
                      {transaction?.sender?.userId?._id ===
                      authInfo?.profile?.id ? (
                        <h2 className="text-xs md:text-sm lg:text-sm">
                          {transaction?.receiver?.userId?.first_name}{" "}
                          {transaction?.receiver?.userId?.last_name}
                        </h2>
                      ) : (
                        <h1 className="text-xs md:text-sm lg:text-sm">
                          {transaction?.sender?.userId?.first_name}{" "}
                          {transaction?.sender?.userId?.last_name}
                        </h1>
                      )}
                    </TableCell>
                    <TableCell>
                      {formatCurrency(
                        transaction?.amount,
                        currencyIcon,
                        currencyCode
                      )}
                    </TableCell>
                    <TableCell>{transaction?.transactionId}</TableCell>
                    <TableCell>
                      {transaction?.type === "transfer" ? (
                        <Badge type="success">
                          <span className="dark:!text-gray-400">
                            {transaction?.type}
                          </span>
                        </Badge>
                      ) : transaction?.type === "withdrawal" ? (
                        <Badge type="neutral">
                          <span className="dark:!text-gray-400">Withdraw</span>
                        </Badge>
                      ) : (
                        <Badge type="success">
                          <span className="dark:!text-gray-400">
                            {transaction?.type}
                          </span>
                        </Badge>
                      )}
                    </TableCell>
                    <TableCell>
                      {transaction?.status === "completed" ? (
                        <Badge type="success">
                          <span className="dark:!text-gray-400">
                            {transaction?.status}
                          </span>
                        </Badge>
                      ) : transaction?.status === "pending" ? (
                        <Badge>
                          <span className="dark:!text-gray-400">
                            {transaction?.status}
                          </span>
                        </Badge>
                      ) : (
                        <Badge type="danger">
                          <span className="dark:!text-gray-400">
                            {transaction?.status}
                          </span>
                        </Badge>
                      )}
                    </TableCell>
                    <TableCell>
                      {new Date(transaction?.createdAt)?.toLocaleString()}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={filteredDatas?.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          className="dark:!text-white"
        />
      </div>
    </section>
  );
};

export default DepositHistory;
