// AdSense.js
import React from "react";

const AdSense = () => (
  <script
    async
    src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-9103934554917713"
    crossOrigin="anonymous"
  ></script>
);

export default AdSense;
