import React, { useState, Suspense, lazy, useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import LoadingPage from "../pages/LoadingPage";
import CashierHeader from "../roleHeaders/CashierHeader";
import CashierDashboard from "../roleDashboards/CashierDashboard";
import Messenger from "../chat";
// import CustomerList from "../pages/marketer/CustomerList";
// import RecordDeposit from "../pages/marketer/RecordDeposit";
// import DepositHistory from "../pages/marketer/DepositHistory";

const UserProfilePage = lazy(() => import("../pages/user/UserProfilePage"));
const Withdrawal = lazy(() => import("../pages/bank/Withdrawal"));
const Deposits = lazy(() => import("../pages/bank/pages/Deposits"));

const CashierNavigator = ({ favicon }) => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 3000);

    return () => clearTimeout(timer);
  }, []);

  if (isLoading) {
    return <LoadingPage />;
  }

  return (
    <div className="flex dark:bg-primary bg-white w-full">
      {/* <CashierNavbar /> */}
      <div className="flex-1 w-full max-w-screen-xxl">
        <CashierHeader
          // onCreateBranchUserClick={displayCreateBranchUserModal}
          favicon={favicon}
        />
        <Routes>
          <Route
            path="/"
            element={
              <Suspense
                fallback={
                  <div>
                    <LoadingPage />
                  </div>
                }
              >
                <CashierDashboard />
              </Suspense>
            }
          />
          <Route
            path="/profile"
            element={
              <Suspense
                fallback={
                  <div>
                    <LoadingPage />
                  </div>
                }
              >
                <UserProfilePage />
              </Suspense>
            }
          />
          <Route
            path="/cashier/customers"
            element={
              <Suspense
                fallback={
                  <div>
                    <LoadingPage />
                  </div>
                }
              >
                {/* <CustomerList /> */}
              </Suspense>
            }
          />
          <Route
            path="/cashier/deposit"
            element={
              <Suspense
                fallback={
                  <div>
                    <LoadingPage />
                  </div>
                }
              >
                {/* <RecordDeposit /> */}
              </Suspense>
            }
          />
          <Route
            path="/cashier/deposits"
            element={
              <Suspense
                fallback={
                  <div>
                    <LoadingPage />
                  </div>
                }
              >
                {/* <DepositHistory /> */}
              </Suspense>
            }
          />
          <Route
            path="/cashier/messageStaff"
            element={
              <Suspense
                fallback={
                  <div>
                    <LoadingPage />
                  </div>
                }
              >
                <Messenger />
              </Suspense>
            }
          />
          <Route
            path="/finance/withdrawal-requests"
            element={
              <Suspense
                fallback={
                  <div>
                    <LoadingPage />
                  </div>
                }
              >
                <Withdrawal />
              </Suspense>
            }
          />
          <Route
            path="/finance/deposit-transactions"
            element={
              <Suspense
                fallback={
                  <div>
                    <LoadingPage />
                  </div>
                }
              >
                <Deposits />
              </Suspense>
            }
          />
        </Routes>
      </div>
    </div>
  );
};

export default CashierNavigator;
