import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { getCustomer } from "../../../api/marketer/marketer";
import { ImSpinner3 } from "react-icons/im";
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableContainer,
  Paper,
  TextField,
  Button,
  TablePagination,
} from "@mui/material";
import Title from "../../form/Title";
import { formatCurrency, getCurrencyIcon } from "../../../utils/helper";

const CustomerList = () => {
  const [customers, setCustomers] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [busy, setBusy] = useState(false);
  const [currencyIcon, setCurrencyIcon] = useState("");
  const [currencyCode, setCurrencyCode] = useState("");

  useEffect(() => {
    const fetchCurrencyIcon = async () => {
      try {
        setBusy(true);
        const data = await getCurrencyIcon();
        setCurrencyIcon(data?.currencyIcon);
        setCurrencyCode(data?.currencyCode);
        setBusy(false);
      } catch (error) {
        setBusy(false);
        console.error("Error fetching currency icon:", error);
      }
    };

    fetchCurrencyIcon();
  }, []);

  const getCustomers = async () => {
    try {
      setBusy(true);
      const customer = await getCustomer();
      setCustomers(customer);
      setBusy(false);
    } catch (error) {
      setBusy(false);
      console.log(error);
    }
  };

  useEffect(() => {
    getCustomers();
  }, []);

  const filteredDatas = customers?.filter(
    (dat) =>
      dat?.first_name?.toLowerCase()?.includes(searchTerm?.toLowerCase()) ||
      dat?.last_name?.toLowerCase()?.includes(searchTerm?.toLowerCase())
  );
  const paginatedDatas = filteredDatas?.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event?.target?.value, 10));
    setPage(0);
  };

  return (
    <section className="mt-10 p-5 max-h-screen !w-full overflow-y-auto siderScroll custom-scroll-bar bg-white dark:!bg-[#182235]">
      <div>
        <>
          {busy && (
            <div className="flex justify-center items-center text-gray-700 dark:text-center">
              <h2 className="flex text-white">
                L<ImSpinner3 className="animate-spin" />
                ading...
              </h2>
            </div>
          )}
          <Title>All Customers</Title>
          {customers && customers?.length > 0 ? (
            <>
              <div className="flex">
                <TextField
                  label="Search"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  className="!bg-white"
                />
              </div>
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Acc/Name</TableCell>
                      <TableCell>Acc/Number</TableCell>
                      <TableCell>Email</TableCell>
                      <TableCell>PhoneNumber</TableCell>
                      <TableCell>Account</TableCell>
                      <TableCell>Balance</TableCell>
                      <TableCell>Status</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {paginatedDatas?.map((data, index) => {
                      // console.log("data::", data);
                      return (
                        <TableRow key={index}>
                          <TableCell>
                            {data?.account?.userId?.first_name +
                              " " +
                              data?.account?.userId?.last_name}
                          </TableCell>
                          <TableCell>{data?.account?.accountNumber}</TableCell>
                          <TableCell>{data?.email}</TableCell>
                          <TableCell>
                            {data?.account?.userId?.phoneNumber}
                          </TableCell>
                          <TableCell>{data?.account?.accountType}</TableCell>
                          <TableCell>
                            {formatCurrency(
                              data?.account?.balance || 0,
                              currencyIcon,
                              currencyCode
                            )}
                          </TableCell>

                          <TableCell>
                            {data?.account?.status === "active" ? (
                              <span className="bg-green-500 rounded-md px-2 font-semibold text-white">
                                {data?.account?.status}
                              </span>
                            ) : (
                              <span className="bg-yellow-500 rounded-md px-2 font-semibold text-red-500">
                                {data?.account?.status}
                              </span>
                            )}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={filteredDatas?.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                className="dark:!text-white"
              />
            </>
          ) : (
            <div>No User found.</div>
          )}
        </>
      </div>
    </section>
  );
};

export default CustomerList;
