import React from "react";

export default function FormInput({ name, label, placeholder, ...rest }) {
  return (
    <div className="flex flex-col-reverse register__input">
      <input
        id={name}
        name={name}
        className="bg-transparent rounded border-2 dark:!border-dark-subtle !border-light-subtle w-full sm:!text-[20px] !text-sm !outline-light-subtle dark:focus:!border-blue-500 focus:!border-primary p-1 dark:!text-white !text-primary peer transition px-4"
        placeholder={placeholder}
        {...rest}
      />
      <label
        className="font-semibold dark:!text-dark-subtle !text-primary dark:peer-focus:!text-white peer-focus:!text-primary transition self-start text-sm"
        htmlFor={name}
      >
        {label}
      </label>
    </div>
  );
}
