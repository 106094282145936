// NetworkStatus.js

import React, { useState, useEffect } from "react";

const NetworkStatus = () => {
  const [online, setOnline] = useState(window.navigator.onLine);
  const [isVisible, setIsVisible] = useState(true);
  const [wasOffline, setWasOffline] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsVisible(false);
    }, 5000);

    return () => clearTimeout(timer);
  }, [isVisible]);

  useEffect(() => {
    const handleOnline = () => {
      setOnline(true);
      if (wasOffline) {
        setIsVisible(true);
      }
      setWasOffline(false);
    };
    const handleOffline = () => {
      setOnline(false);
      setIsVisible(true);
      setWasOffline(true);
    };

    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOffline);

    return () => {
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);
    };
  }, [wasOffline]);

  return (
    <>
      {isVisible && (
        <div
          className={`network-message text-center items-center pt-3 rounded-lg ${
            online
              ? "w-full bg-green-500 text-white"
              : "w-full bg-red-500 text-white"
          }`}
        >
          {!online ? (
            <p>
              Network error!!:: Please check your internet connection and try
              again.
            </p>
          ) : (
            <p>You're back online</p>
          )}
        </div>
      )}
    </>
  );
};

export default NetworkStatus;
