import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Dropdown } from "antd";

export default function NavigationBar({ logo }) {
  const [show, handleShow] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [nav, setNav] = useState(false);

  const navigate = useNavigate();

  const items = [
    {
      key: "1",
      label: "wanna know about us?",
      disabled: true,
    },
    {
      key: "2",
      label: (
        <Link
          to="/about"
          className="font-semibold font-serif"
          onClick={(e) => {
            e.preventDefault();
            navigate("/about");
            window.location.reload();
          }}
        >
          About Us
        </Link>
      ),
    },
    {
      key: "3",
      label: "Our Team?",
      disabled: true,
    },
    {
      key: "4",
      label: (
        <Link
          to="/team"
          className="font-semibold font-serif"
          onClick={(e) => {
            e.preventDefault();
            navigate("/team");
            window.location.reload();
          }}
        >
          Team
        </Link>
      ),
    },
  ];

  const navList = (
    <ul className="mt-3 mb-4 ml-5 flex flex-col gap-2 lg:!mb-0 lg:!mt-0 lg:!flex-row lg:!items-center lg:!gap-6 hover:text-blueGray-500 text-blueGray-700">
      <li className="p-1 font-normal">
        <Link
          to="/"
          className="lg:text-white lg:hover:text-blueGray-200 text-blueGray-700 px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold"
        >
          Home
        </Link>
      </li>
      <Dropdown menu={{ items }}>
        <li className="p-1 font-normal">
          <Link
            className="lg:text-white lg:hover:text-blueGray-200 text-blueGray-700 px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold"
            onClick={(e) => e.preventDefault()}
          >
            Company
          </Link>
        </li>
      </Dropdown>
      <li className="p-1 font-normal">
        <Link
          className="lg:text-white lg:hover:text-blueGray-200 text-blueGray-700 px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold"
          onClick={(e) => {
            e.preventDefault();
            navigate("/auth/signin");
            window.location.reload();
          }}
        >
          Login
        </Link>
      </li>
      <li className="p-1 font-normal">
        <Link
          className="lg:text-white lg:hover:text-blueGray-200 text-blueGray-700 px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold"
          onClick={(e) => {
            e.preventDefault();
            navigate("/auth/signup");
            window.location.reload();
          }}
        >
          Register
        </Link>
      </li>
    </ul>
  );

  React.useEffect(() => {
    window.addEventListener(
      "resize",
      () => window.innerWidth >= 960 && setNav(false)
    );
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 100) {
        handleShow(true);
      } else handleShow(false);
    });
    return () => {
      window.removeEventListener("scroll", null);
    };
  }, []);

  const handleNavToggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <nav className="top-0 absolute z-50 w-full flex flex-wrap items-center justify-between px-2 py-3 navbar-expand-lg bg-blueGray-800">
        <div className="container px-4 mx-auto flex flex-wrap items-center justify-between">
          <div className="w-full relative flex justify-between lg:w-auto lg:static lg:block lg:justify-start ">
            <a
              className="text-sm font-bold leading-relaxed inline-block mr-4 py-2 whitespace-nowrap uppercase text-white"
              href="/"
            >
              RoyalKatd
            </a>
            <button
              className="cursor-pointer text-xl leading-none px-3 py-1 border border-solid border-transparent rounded bg-transparent block lg:hidden outline-none focus:outline-none"
              type="button"
              onClick={handleNavToggle}
            >
              {isOpen ? (
                <i className="text-white fa-solid fa-xmark" />
              ) : (
                <i className="text-white fa-solid fa-bars" />
              )}
            </button>
          </div>
          <div
            className={`lg:flex flex-grow items-center ${
              isOpen ? "block" : "hidden"
            } lg:block lg:bg-opacity-0 lg:shadow-none bg-blueGray-800`}
          >
            <ul className="flex flex-col lg:flex-row list-none lg:ml-auto items-center">
              <li className="inline-block relative">
                <div className="lg:text-white lg:hover:text-blueGray-200 text-blueGray-700 px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold">
                  {navList}
                </div>
              </li>
              <li className="flex items-center">
                <div className="lg:text-white lg:hover:text-blueGray-200 text-blueGray-700 px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold">
                  <i class="lg:text-blueGray-200 text-blueGray-400 fa-solid fa-money-check-dollar text-lg leading-lg"></i>
                  <span className="lg:hidden inline-block ml-2">
                    RoyalKatd Enterprise
                  </span>
                </div>
              </li>
              <li className="flex items-center">
                <div className="lg:text-white lg:hover:text-blueGray-200 text-blueGray-700 px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold">
                  <i class="lg:text-blueGray-200 text-blueGray-400 fa-solid fa-coins text-lg leading-lg"></i>
                  <span className="lg:hidden inline-block ml-2">Finance</span>
                </div>
              </li>

              <li className="flex items-center">
                <div className="bg-white text-blueGray-700 active:bg-blueGray-50 text-xs font-bold uppercase px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none lg:mr-1 lg:mb-0 ml-3 mb-3 ease-linear transition-all duration-150">
                  <i className="fa-solid fa-hand-holding-dollar"></i>RoyalKatd
                </div>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
}
