import React, { useEffect, useState } from "react";
import { allTransactions } from "../../../api/transactions";
import moment from "moment";

const TransactionsTable = ({ formatCurrency, currencyCode, currencyIcon }) => {
  const [transactions, setTransactions] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");

  const fetchTransactions = async (page) => {
    try {
      const res = await allTransactions(page, 10);
      console.log("res", res);
      setTransactions(res.transactions);
      setTotalPages(res.totalPages);
    } catch (error) {
      console.error("Error fetching transactions:", error);
    }
  };

  useEffect(() => {
    fetchTransactions(currentPage);
  }, [currentPage]);

  const handlePageChange = (newPage) => {
    if (newPage > 0 && newPage <= totalPages) {
      setCurrentPage(newPage);
    }
  };

  const handleSearch = (e) => {
    setSearchQuery(e.target.value.toLowerCase());
  };

  console.log("transactions::", transactions);

  const filteredTransactions = transactions?.filter((transaction) =>
    `${transaction?.sender} ${transaction?.receiver}`
      .toLowerCase()
      .includes(searchQuery)
  );
  return (
    <div className="transactions-table">
      <div className="overflow-x-auto">
        <p className="font-medium">Transactions</p>
        <input
          type="text"
          placeholder="Search transactions..."
          className="my-4 p-2 border border-gray-300 rounded"
          value={searchQuery}
          onChange={handleSearch}
        />

        <table className="w-full whitespace-nowrap">
          <thead>
            <tr className="bg-[rgba(67, 113, 233, .05)]1/5 dark:bg-blue-300">
              <th className="text-start py-5 px-6 cursor-pointer min-w-[300px]">
                <TableHeader title="Sender" />
              </th>
              <th className="text-start py-5 px-6 cursor-pointer min-w-[300px]">
                <TableHeader title="Receiver" />
              </th>
              <th className="text-start py-5 w-[14%] cursor-pointer">
                <TableHeader title="Amount" />
              </th>
              <th className="text-start py-5 w-[14%] cursor-pointer">
                <TableHeader title="Date and Time" />
              </th>
            </tr>
          </thead>
          <tbody>
            {filteredTransactions?.map((transaction, index) => (
              <TransactionRow
                key={index}
                transaction={transaction}
                formatCurrency={formatCurrency}
                currencyCode={currencyCode}
                currencyIcon={currencyIcon}
              />
            ))}
          </tbody>
        </table>
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={handlePageChange}
        />
      </div>
    </div>
  );
};

const TableHeader = ({ title }) => (
  <div className="flex items-center gap-1">
    {title}
    <SvgIcon />
  </div>
);

const SvgIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={18}
    height={18}
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth={2}
    strokeLinecap="round"
    strokeLinejoin="round"
    className="tabler-icon tabler-icon-selector"
  >
    <path d="M8 9l4 -4l4 4" />
    <path d="M16 15l-4 4l-4 -4" />
  </svg>
);

const TransactionRow = ({
  transaction,
  formatCurrency,
  currencyCode,
  currencyIcon,
}) => {
  const { sender, receiver, amount, date } = transaction;
  const formattedDate = moment(date).format("DD MMM, YY. hh:mm a");

  return (
    <tr className="bg-[rgba(67, 113, 233, .05)]1/5 dark:bg-blue-300">
      <td className="py-2 px-6">{sender}</td>
      <td className="py-2 px-6">{receiver}</td>
      <td className="py-2">
        <div>
          <p className="font-medium">
            {formatCurrency(amount, currencyIcon, currencyCode)}
          </p>
        </div>
      </td>
      <td className="py-2">{formattedDate}</td>
    </tr>
  );
};

const Pagination = ({ currentPage, totalPages, onPageChange }) => {
  const pagesToShow = 5;
  const startPage = Math.max(currentPage - Math.floor(pagesToShow / 2), 1);
  const endPage = Math.min(startPage + pagesToShow - 1, totalPages);

  const pages = [];
  for (let i = startPage; i <= endPage; i++) {
    pages.push(i);
  }

  return (
    <div className="flex justify-center my-4">
      <button
        onClick={() => onPageChange(currentPage - 1)}
        disabled={currentPage === 1}
        className="px-3 py-1 mx-1 bg-gray-300 rounded"
      >
        &lt;
      </button>
      {pages.map((page) => (
        <button
          key={page}
          onClick={() => onPageChange(page)}
          className={`px-3 py-1 mx-1 ${
            page === currentPage ? "bg-blue-500 text-white" : "bg-gray-300"
          } rounded`}
        >
          {page}
        </button>
      ))}
      <button
        onClick={() => onPageChange(currentPage + 1)}
        disabled={currentPage === totalPages}
        className="px-3 py-1 mx-1 bg-gray-300 rounded"
      >
        &gt;
      </button>
    </div>
  );
};

export default TransactionsTable;
