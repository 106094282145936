import React, { useState } from "react";
import { useNotification } from "../hooks";
import { contributeOnGoal } from "../../api/cashier/cashier";
import { FormLabel } from "@mui/material";

const GoalContributionForm = ({ goalId, onContributionSuccess }) => {
  const [amount, setAmount] = useState("");
  const [loading, setLoading] = useState(false);

  const { updateNotification } = useNotification();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      // Simulate a delay of 2-3 seconds
      await new Promise((resolve) => setTimeout(resolve, 2000));
      const res = await contributeOnGoal(goalId, amount);
      if (res?.error) {
        updateNotification("error", res?.error);
      } else {
        updateNotification("success", "Contribution Successful!");
      }
      onContributionSuccess();
    } catch (error) {
      updateNotification("error", "Error contributing to spending goal!");
      console.error("Error contributing to spending goal:", error);
    } finally {
      setLoading(false);
    }
  };
  return (
    <form onSubmit={handleSubmit} className="mb-6 bg-gray-700 p-20">
      <div className="mb-4">
        <FormLabel
          className="!text-white text-sm font-bold mb-2"
          htmlFor="amount"
        >
          Contribution Amount
        </FormLabel>
        <input
          type="number"
          id="amount"
          value={amount}
          onChange={(e) => setAmount(e.target.value)}
          required
          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          disabled={loading}
        />
      </div>

      <button
        type="submit"
        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
        disabled={loading}
      >
        {loading ? "Contributing on Goal..." : "Contribute"}
      </button>
    </form>
  );
};

export default GoalContributionForm;
