import React, { useEffect, useState } from "react";
import { GetFiveRecentTransactions } from "../../../api/transactions";
import moment from "moment";

const FiveRecentTransactions = ({
  formatCurrency,
  currencyCode,
  currencyIcon,
}) => {
  const [transactions, setTransactions] = useState([]);

  const allTransactions = async () => {
    try {
      const res = await GetFiveRecentTransactions();
      console.log("resssss:", res);
      setTransactions(res?.data);
    } catch (error) {
      console.error("Error fetching accounts:", error);
    }
  };

  useEffect(() => {
    allTransactions();
  }, []);

  return (
    <div className="accounts-table">
      <div className="overflow-x-auto">
        <p className="font-medium">My Recent Transactions</p>
        <table className="w-full whitespace-nowrap">
          <thead>
            <tr className="bg-[rgba(67, 113, 233, .05)]1/5 dark:bg-blue-300">
              <th className="text-start py-5 px-6 cursor-pointer min-w-[300px]">
                <TableHeader title="Sender" />
              </th>
              <th className="text-start py-5 px-6 cursor-pointer min-w-[300px]">
                <TableHeader title="Receiver" />
              </th>
              <th className="text-start py-5 w-[14%] cursor-pointer">
                <TableHeader title="Amount" />
              </th>
              <th className="text-start py-5 w-[14%] cursor-pointer">
                <TableHeader title="Date and Time" />
              </th>
            </tr>
          </thead>
          <tbody>
            {transactions?.map((transaction, index) => (
              <TransactionRow
                key={index}
                transaction={transaction}
                formatCurrency={formatCurrency}
                currencyCode={currencyCode}
                currencyIcon={currencyIcon}
              />
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

const TableHeader = ({ title }) => (
  <div className="flex items-center gap-1">
    {title}
    <SvgIcon />
  </div>
);

const SvgIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={18}
    height={18}
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth={2}
    strokeLinecap="round"
    strokeLinejoin="round"
    className="tabler-icon tabler-icon-selector"
  >
    <path d="M8 9l4 -4l4 4" />
    <path d="M16 15l-4 4l-4 -4" />
  </svg>
);

const TransactionRow = ({
  transaction,
  formatCurrency,
  currencyCode,
  currencyIcon,
}) => {
  const formattedDate = moment(transaction?.createdAt).format(
    "DD MMM, YY. hh:mm a"
  );
  const amount = transaction?.amount;
  const sender =
    transaction?.sender?.userId?.first_name +
    " " +
    transaction?.sender?.userId?.last_name;
  const receiver =
    transaction?.receiver?.userId?.first_name +
    " " +
    transaction?.receiver?.userId?.last_name;

  return (
    <tr className="bg-[rgba(67, 113, 233, .05)]1/5 dark:bg-blue-300">
      <td className="py-2 px-6">{sender}</td>
      <td className="py-2 px-6">{receiver}</td>
      <td className="py-2">
        <div>
          <p className="font-medium">
            {formatCurrency(amount, currencyIcon, currencyCode)}
          </p>
        </div>
      </td>
      <td className="py-2">{formattedDate}</td>
    </tr>
  );
};

export default FiveRecentTransactions;
