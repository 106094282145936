import axios from "axios";
import { catchError, getToken } from "../../utils/helper";
import client from "../client";

const token = getToken();

export const fetchTransactionAnalytics = async (userId) => {
  try {
    const { data } = await client.get(
      `/transactions/transaction-analytics/${userId}`,
      {
        headers: {
          authorization: "Bearer " + token,
        },
      }
    );
    return data;
  } catch (error) {
    console.error("Error fetching transaction analytics", error);
    return catchError(error);
  }
};
export const createSpendingGoal = async ({
  userId,
  goalAmount,
  dueDate,
  note,
}) => {
  try {
    const { data } = await client.post(
      `/goal/create`,
      {
        userId,
        goalAmount,
        dueDate,
        note,
      },
      {
        headers: {
          authorization: "Bearer " + token,
        },
      }
    );
    return data;
  } catch (error) {
    console.error("Error creating spending goal", error);
    return catchError(error);
  }
};
export const contributeOnGoal = async (goalId, amount) => {
  try {
    const { data } = await client.put(
      `/goal/contribute/${goalId}`,
      {
        amount,
      },
      {
        headers: {
          authorization: "Bearer " + token,
        },
      }
    );
    return data;
  } catch (error) {
    console.error("Error contributing to goal", error);
    return catchError(error);
  }
};
export const getSpendingGoals = async (userId) => {
  try {
    const { data } = await client.get(`/goal/${userId}`, {
      headers: {
        authorization: "Bearer " + token,
      },
    });
    return data;
  } catch (error) {
    console.error("Error getting spending goal", error);
    return catchError(error);
  }
};
export const handleUpdateGoal = async (id, updatedData) => {
  try {
    const { data } = await client.put(`/goal/update/${id}`, updatedData, {
      headers: {
        authorization: "Bearer " + token,
      },
    });
    return data;
  } catch (error) {
    console.error("Error getting spending goal", error);
    return catchError(error);
  }
};
