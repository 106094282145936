import axios from "axios";
import { catchError, getToken } from "../../utils/helper";
import client from "../client";

const token = getToken();

export const getCustomer = async () => {
  try {
    const { data } = await client.get("/account/customers", {
      headers: {
        authorization: "Bearer " + token,
      },
    });
    return data;
  } catch (error) {
    return catchError(error);
  }
};
export const getMarketers = async () => {
  try {
    const { data } = await client.get("/account/marketers", {
      headers: {
        authorization: "Bearer " + token,
      },
    });
    return data;
  } catch (error) {
    return catchError(error);
  }
};
export const assignCustomer = async ({ customerId, marketerId }) => {
  try {
    const { data } = await client.post(
      "/account/assign-customer",
      { customerId, marketerId },
      {
        headers: {
          authorization: "Bearer " + token,
        },
      }
    );
    return data;
  } catch (error) {
    return catchError(error);
  }
};
export const unassignCustomer = async ({ customerId }) => {
  try {
    const { data } = await client.post(
      "/account/unassignCustomer",
      { customerId },
      {
        headers: {
          authorization: "Bearer " + token,
        },
      }
    );
    return data;
  } catch (error) {
    return catchError(error);
  }
};
export const recordDeposit = async (depositData) => {
  try {
    const { data } = await client.post("/account/record-deposit", depositData, {
      headers: {
        authorization: "Bearer " + token,
      },
    });
    return data;
  } catch (error) {
    return catchError(error);
  }
};
export const spendingData = async () => {
  try {
    const { data } = await client.get("/account/marketer/spend", {
      headers: {
        authorization: "Bearer " + token,
      },
    });
    return data;
  } catch (error) {
    return catchError(error);
  }
};
export const customersAssigned = async () => {
  try {
    const { data } = await client.get("/account/marketer/customers", {
      headers: {
        authorization: "Bearer " + token,
      },
    });
    return data;
  } catch (error) {
    return catchError(error);
  }
};
export const getDepositHistory = async () => {
  try {
    const { data } = await client.get("/account/deposit-history", {
      headers: {
        authorization: "Bearer " + token,
      },
    });
    return data;
  } catch (error) {
    return catchError(error);
  }
};
