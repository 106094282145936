import React, { useEffect, useState } from "react";
import { useAuth, useNotification } from "../../hooks";
import Title from "../../form/Title";
import Submit from "../../form/Submit";
import { getCustomer, recordDeposit } from "../../../api/marketer/marketer";
import Select from "react-select";
import FormInput from "../../form/FormInput";
import { commonInputClasses, commonModalClasses } from "../../../utils/theme";
import { getCurrencyIcon } from "../../../utils/helper";

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  CircularProgress,
} from "@mui/material";

const RecordDeposit = () => {
  const [displayAmount, setDisplayAmount] = useState("");
  const [amount, setAmount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [customers, setCustomers] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [currencyIcon, setCurrencyIcon] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const [customerImage, setCustomerImage] = useState("");

  const { updateNotification } = useNotification();

  useEffect(() => {
    const fetchCurrencyIcon = async () => {
      try {
        setLoading(true);
        const data = await getCurrencyIcon();
        setCurrencyIcon(data?.currencyIcon);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.error("Error fetching currency icon:", error);
      }
    };

    fetchCurrencyIcon();
  }, []);

  const formatNumber = (num) => {
    return num.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,");
  };
  const handleAmountChange = (e) => {
    // Get the value from the event and remove any non-numeric characters
    const inputAmount = e.target.value.replace(/[^0-9]/g, "");
    // Convert the cleaned input to a number for the backend
    const numericAmount = parseInt(inputAmount, 10) || 0;
    // Format the numeric amount for display only
    const formattedAmount = formatNumber(numericAmount / 100);
    // Update the state for the displayed amount
    setDisplayAmount(formattedAmount);
    setAmount(numericAmount / 100);
  };

  const getCustomers = async () => {
    try {
      setLoading(true);
      const customer = await getCustomer();
      setCustomers(customer || []);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    getCustomers();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!selectedCustomer) {
      updateNotification("error", "Please select a customer.");
      return;
    }

    // Fetch customer image here
    setCustomerImage(selectedCustomer?.image);
    setOpenDialog(true);
  };

  const handleContinue = async () => {
    try {
      setLoading(true);
      const depositData = {
        customerId: selectedCustomer?.value,
        amount: parseFloat(amount),
      };
      const response = await recordDeposit(depositData);
      if (response?.message === "Deposit recorded successfully") {
        updateNotification("success", response?.message);
        setDisplayAmount("");
        setSelectedCustomer(null);
      } else {
        updateNotification("error", response?.message);
      }
    } catch (error) {
      updateNotification("error", error);
    } finally {
      setLoading(false);
      setOpenDialog(false);
    }
  };

  const handleAbort = () => {
    setOpenDialog(false);
  };

  const colorStyles = {
    option: (provided, state) => ({
      ...provided,
      color: "black",
    }),
  };

  const customerOptions = Array?.isArray(customers)
    ? customers?.map((customer) => ({
        value: customer?._id,
        label: `${customer?.first_name} ${customer?.last_name}`,
        image: customer.picture,
      }))
    : [];

  return (
    <div className="!flex !items-center !justify-center  h-full">
      <div className="max-w-sm mx-auto bg-white dark:!bg-[#1f2b42] rounded-lg overflow-hidden shadow-lg max-h-[25rem] p-5">
        <Title>Record Deposit</Title>
        <form onSubmit={handleSubmit} className={commonModalClasses}>
          <div>
            <label>Customer ID</label>
            <Select
              options={customerOptions}
              value={selectedCustomer}
              onChange={setSelectedCustomer}
              placeholder="Select a customer"
              isSearchable
              styles={colorStyles}
              isClearable
            />
          </div>
          <div className="relative mb-2">
            <span className="absolute left-2 top-1/2 transform -translate-y-1/2 text-gray-500 border-r-2 dark:!border-dark-subtle !border-light-subtle pr-2">
              {currencyIcon}
            </span>
            <FormInput
              label="Amount"
              id="amount"
              name="amount"
              // value={amount}
              value={displayAmount}
              type="text"
              required
              // onChange={(e) => setAmount(e.target.value)}
              onChange={handleAmountChange}
              className={commonInputClasses + " border-b-2 pl-10"}
            />
          </div>
          <Submit type="submit" value="Record Deposit" busy={loading} />
        </form>
      </div>
      {/* Dialog for confirmation */}
      <Dialog open={openDialog} onClose={handleAbort}>
        <DialogTitle>Confirm Deposit</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to continue with the deposit?
          </DialogContentText>
          {customerImage && (
            <img
              src={customerImage}
              alt="Customer"
              style={{ width: "100px", height: "100px", borderRadius: "50%" }}
            />
          )}
          {loading && <CircularProgress />}
        </DialogContent>
        <DialogActions>
          <button onClick={handleAbort} color="primary">
            Abort
          </button>
          <button onClick={handleContinue} color="primary" disabled={loading}>
            Continue
          </button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default RecordDeposit;
