import React, { useEffect, useState } from "react";
import { getUserConversations } from "../../api/messenger/messenger";
import { toast } from "react-toastify";

const useGetConversations = () => {
  const [loading, setLoading] = useState(false);
  const [conversations, setConversations] = useState([]);

  useEffect(() => {
    const getConversations = async () => {
      setLoading(true);
      try {
        const res = await getUserConversations();
        // const data = await res.json();
        if (res.error) {
          throw new Error(res.error);
        }
        setConversations(res);
      } catch (error) {
        toast.error(error.message);
      } finally {
        setLoading(false);
      }
    };

    getConversations();
  }, []);

  return { loading, conversations };
};

export default useGetConversations;
