import React from "react";

export default function Container({ children, className }) {
  return (
    <div
      className={
        "text-white w-full max-w-screen-xl mx-auto px-2 xl:p-0 flex justify-center items-center " +
        className
      }
    >
      {children}
    </div>
  );
}
