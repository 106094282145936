import React from "react";
import { Button, Drawer, Layout, Space } from "antd";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { useAuth } from "../hooks";
import { FiLogOut } from "react-icons/fi";

const { Header } = Layout;

const CashierHeader = ({ favicon }) => {
  const { authInfo, handleLogout } = useAuth();
  const navigate = useNavigate();

  // console.log("authInfo::", authInfo);

  return (
    <div className="!mb-20">
      <div className="fixed z-10 w-full">
        <header className="flex justify-between items-center py-5 border-b">
          <div className="logo">
            <Link to="/">
              <img
                alt="Logo"
                src={favicon}
                style={{ height: 32, width: 50, marginLeft: 16 }}
                className="text-black items-start"
              />
            </Link>
          </div>

          <div className="mt-5 flex justify-center sm:mt-0">
            <Link
              className="group inline-flex ring-1 items-center justify-center rounded-full py-2 px-4 text-sm focus:outline-none ring-slate-500 text-slate-800 hover:bg-slate-100 active:bg-slate-100 active:text-slate-900 focus-visible:outline-slate-600 focus-visible:ring-slate-300 flex items-center justify-center rounded-md bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50"
              to="/cashier/messageStaff"
            >
              Message
            </Link>
          </div>

          <div className="flex items-center space-x-3 px-10">
            <NavLink
              end
              to="/"
              onClick={handleLogout}
              className="block text-red-700 hover:text-slate-200 transition duration-150 truncate"
            >
              <span className="flex text-center items-center text-lg font-medium lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                <FiLogOut /> Sign out
              </span>
            </NavLink>
          </div>
        </header>
      </div>
    </div>
  );
};

export default CashierHeader;
