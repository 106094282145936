import React, { useEffect, useState } from "react";
import { formatCurrency, getCurrencyIcon } from "../../../utils/helper";
import { FaArrowUpWideShort, FaArrowDownWideShort } from "react-icons/fa6";

const FinancialStatCard = ({
  title,
  value,
  percentage,
  isPositive,
  spendingGoal,
  handleGoalModal,
}) => {
  const [currencyIcon, setCurrencyIcon] = useState("");
  const [currencyCode, setCurrencyCode] = useState("");
  const [displayPercentage, setDisplayPercentage] = useState(0);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleToggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const fetchCurrencyIcon = async () => {
    try {
      const data = await getCurrencyIcon();
      setCurrencyIcon(data?.currencyIcon);
      setCurrencyCode(data?.currencyCode);
    } catch (error) {
      console.error("Error fetching currency icon:", error);
    }
  };

  useEffect(() => {
    fetchCurrencyIcon();
  }, []);

  useEffect(() => {
    let start = 0;
    const end = percentage;
    const duration = 1000; // Duration of the animation in ms
    const stepTime = Math.abs(Math.floor(duration / (end - start)));
    let timer = setInterval(() => {
      start += 1;
      setDisplayPercentage(start);
      if (start >= end) clearInterval(timer);
    }, stepTime);

    return () => clearInterval(timer);
  }, [percentage]);

  const amount = formatCurrency(
    value === 0 ? 0 : value,
    currencyIcon,
    currencyCode
  );

  return (
    <div className="col-span-2 sm:col-span-1 box bg-blue-300 dark:bg-blue-500">
      <div className="flex justify-between items-center mb-4 lg:mb-3 pb-4 lg:pb-4 pl-2 border-b-dashed">
        <div className="font-medium">
          {title}{" "}
          {spendingGoal && (
            <span
              className="bg-green-500 text-sm px-2 rounded-lg cursor-pointer"
              onClick={handleGoalModal}
            >
              set a Goal
            </span>
          )}
        </div>
        <div className="relative">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width={24}
            height={24}
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
            className="cursor-pointer"
            onClick={handleToggleModal}
          >
            <path d="M5 12m-1 0a1 1 0 1 0 2 0a1 1 0 1 0 -2 0" />
            <path d="M12 12m-1 0a1 1 0 1 0 2 0a1 1 0 1 0 -2 0" />
            <path d="M19 12m-1 0a1 1 0 1 0 2 0a1 1 0 1 0 -2 0" />
          </svg>
          {isModalOpen && (
            <ul className="absolute top-full ltr:right-0 rtl:left-0 duration-300 ltr:origin-top-right rtl:origin-top-left shadow-[0px_6px_30px_0px_rgba(0,0,0,0.08)] min-w-[122px] bg-gray-300 z-[3] dark:bg-gray-200 p-3 rounded-md">
              <li>
                <a
                  className="py-1 leading-normal hover:bg-primary/10 dark:hover:bg-bg4 rounded text-sm duration-300 block px-3"
                  href="#"
                >
                  Edit
                </a>
              </li>
              <li>
                <a
                  className="py-1 leading-normal hover:bg-primary/10 dark:hover:bg-bg4 rounded text-sm duration-300 block px-3"
                  href="#"
                >
                  Print
                </a>
              </li>
              <li>
                <a
                  className="py-1 leading-normal hover:bg-primary/10 dark:hover:bg-bg4 rounded text-sm duration-300 block px-3"
                  href="#"
                >
                  Share
                </a>
              </li>
            </ul>
          )}
        </div>
      </div>
      <div className="flex items-center justify-between gap-4 xl:gap-6 pl-2">
        <div>
          <h4 className="h4 mb-4">{amount}</h4>
          <div>
            <span className="flex items-center gap-1 whitespace-nowrap">
              {isPositive ? (
                <FaArrowUpWideShort color="green" />
              ) : (
                <FaArrowDownWideShort color="red" />
              )}
              {displayPercentage}%
            </span>
          </div>
        </div>
        <div style={{ minHeight: 60, minWidth: 126 }}>
          {/* Placeholder for chart */}
          <div
            options="[object Object]"
            series="[object Object]"
            type="bar"
            height={60}
            width={126}
            style={{ minHeight: 60 }}
          >
            <div
              id="apexcharts2os1p547"
              className="apexcharts-canvas apexcharts2os1p547 apexcharts-theme-light"
              style={{ width: 126, height: 60 }}
            >
              <svg
                id="SvgjsSvg2567"
                width={126}
                height={60}
                xmlns="http://www.w3.org/2000/svg"
                version="1.1"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                // xmlns:svgjs="http://svgjs.dev"
                className="apexcharts-svg"
                // xmlns:data="ApexChartsNS"
                transform="translate(0, 0)"
                style={{ background: "transparent" }}
              >
                <foreignObject x={0} y={0} width={126} height={60}>
                  <div
                    className="apexcharts-legend"
                    xmlns="http://www.w3.org/1999/xhtml"
                    style={{ maxHeight: 30 }}
                  />
                </foreignObject>
                <g
                  id="SvgjsG2622"
                  className="apexcharts-yaxis"
                  rel={0}
                  transform="translate(-18, 0)"
                />
                <g
                  id="SvgjsG2569"
                  className="apexcharts-inner apexcharts-graphical"
                  transform="translate(20.759999999999998, 0)"
                >
                  <defs id="SvgjsDefs2568">
                    <linearGradient
                      id="SvgjsLinearGradient2571"
                      x1={0}
                      y1={0}
                      x2={0}
                      y2={1}
                    >
                      <stop
                        id="SvgjsStop2572"
                        stopOpacity="0.4"
                        stopColor="rgba(216,227,240,0.4)"
                        offset={0}
                      />
                      <stop
                        id="SvgjsStop2573"
                        stopOpacity="0.5"
                        stopColor="rgba(190,209,230,0.5)"
                        offset={1}
                      />
                      <stop
                        id="SvgjsStop2574"
                        stopOpacity="0.5"
                        stopColor="rgba(190,209,230,0.5)"
                        offset={1}
                      />
                    </linearGradient>
                    <clipPath id="gridRectMask2os1p547">
                      <rect
                        id="SvgjsRect2576"
                        width={130}
                        height={64}
                        x="-18.759999999999998"
                        y={-2}
                        rx={0}
                        ry={0}
                        opacity={1}
                        strokeWidth={0}
                        stroke="none"
                        strokeDasharray={0}
                        fill="#fff"
                      />
                    </clipPath>
                    <clipPath id="forecastMask2os1p547" />
                    <clipPath id="nonForecastMask2os1p547" />
                    <clipPath id="gridRectMarkerMask2os1p547">
                      <rect
                        id="SvgjsRect2577"
                        width="96.48"
                        height={64}
                        x={-2}
                        y={-2}
                        rx={0}
                        ry={0}
                        opacity={1}
                        strokeWidth={0}
                        stroke="none"
                        strokeDasharray={0}
                        fill="#fff"
                      />
                    </clipPath>
                  </defs>
                  <rect
                    id="SvgjsRect2575"
                    width={0}
                    height={60}
                    x={0}
                    y={0}
                    rx={0}
                    ry={0}
                    opacity={1}
                    strokeWidth={0}
                    strokeDasharray={3}
                    fill="url(#SvgjsLinearGradient2571)"
                    className="apexcharts-xcrosshairs"
                    y2={60}
                    filter="none"
                    fillOpacity="0.9"
                  />
                  <g id="SvgjsG2595" className="apexcharts-grid">
                    <g
                      id="SvgjsG2596"
                      className="apexcharts-gridlines-horizontal"
                      style={{ display: "none" }}
                    >
                      <line
                        id="SvgjsLine2599"
                        x1="-16.759999999999998"
                        y1={0}
                        x2="109.24000000000001"
                        y2={0}
                        stroke="#e0e0e0"
                        strokeDasharray={0}
                        strokeLinecap="butt"
                        className="apexcharts-gridline"
                      />
                      <line
                        id="SvgjsLine2600"
                        x1="-16.759999999999998"
                        y1={6}
                        x2="109.24000000000001"
                        y2={6}
                        stroke="#e0e0e0"
                        strokeDasharray={0}
                        strokeLinecap="butt"
                        className="apexcharts-gridline"
                      />
                      <line
                        id="SvgjsLine2601"
                        x1="-16.759999999999998"
                        y1={12}
                        x2="109.24000000000001"
                        y2={12}
                        stroke="#e0e0e0"
                        strokeDasharray={0}
                        strokeLinecap="butt"
                        className="apexcharts-gridline"
                      />
                      <line
                        id="SvgjsLine2602"
                        x1="-16.759999999999998"
                        y1={18}
                        x2="109.24000000000001"
                        y2={18}
                        stroke="#e0e0e0"
                        strokeDasharray={0}
                        strokeLinecap="butt"
                        className="apexcharts-gridline"
                      />
                      <line
                        id="SvgjsLine2603"
                        x1="-16.759999999999998"
                        y1={24}
                        x2="109.24000000000001"
                        y2={24}
                        stroke="#e0e0e0"
                        strokeDasharray={0}
                        strokeLinecap="butt"
                        className="apexcharts-gridline"
                      />
                      <line
                        id="SvgjsLine2604"
                        x1="-16.759999999999998"
                        y1={30}
                        x2="109.24000000000001"
                        y2={30}
                        stroke="#e0e0e0"
                        strokeDasharray={0}
                        strokeLinecap="butt"
                        className="apexcharts-gridline"
                      />
                      <line
                        id="SvgjsLine2605"
                        x1="-16.759999999999998"
                        y1={36}
                        x2="109.24000000000001"
                        y2={36}
                        stroke="#e0e0e0"
                        strokeDasharray={0}
                        strokeLinecap="butt"
                        className="apexcharts-gridline"
                      />
                      <line
                        id="SvgjsLine2606"
                        x1="-16.759999999999998"
                        y1={42}
                        x2="109.24000000000001"
                        y2={42}
                        stroke="#e0e0e0"
                        strokeDasharray={0}
                        strokeLinecap="butt"
                        className="apexcharts-gridline"
                      />
                      <line
                        id="SvgjsLine2607"
                        x1="-16.759999999999998"
                        y1={48}
                        x2="109.24000000000001"
                        y2={48}
                        stroke="#e0e0e0"
                        strokeDasharray={0}
                        strokeLinecap="butt"
                        className="apexcharts-gridline"
                      />
                      <line
                        id="SvgjsLine2608"
                        x1="-16.759999999999998"
                        y1={54}
                        x2="109.24000000000001"
                        y2={54}
                        stroke="#e0e0e0"
                        strokeDasharray={0}
                        strokeLinecap="butt"
                        className="apexcharts-gridline"
                      />
                      <line
                        id="SvgjsLine2609"
                        x1="-16.759999999999998"
                        y1={60}
                        x2="109.24000000000001"
                        y2={60}
                        stroke="#e0e0e0"
                        strokeDasharray={0}
                        strokeLinecap="butt"
                        className="apexcharts-gridline"
                      />
                    </g>
                    <g
                      id="SvgjsG2597"
                      className="apexcharts-gridlines-vertical"
                      style={{ display: "none" }}
                    />
                    <line
                      id="SvgjsLine2611"
                      x1={0}
                      y1={60}
                      x2="92.48"
                      y2={60}
                      stroke="transparent"
                      strokeDasharray={0}
                      strokeLinecap="butt"
                    />
                    <line
                      id="SvgjsLine2610"
                      x1={0}
                      y1={1}
                      x2={0}
                      y2={60}
                      stroke="transparent"
                      strokeDasharray={0}
                      strokeLinecap="butt"
                    />
                  </g>
                  <g
                    id="SvgjsG2598"
                    className="apexcharts-grid-borders"
                    style={{ display: "none" }}
                  />
                  <g
                    id="SvgjsG2578"
                    className="apexcharts-bar-series apexcharts-plot-series"
                  >
                    <g
                      id="SvgjsG2579"
                      className="apexcharts-series"
                      rel={1}
                      seriesname="Seriesx1"
                      // data:realindex={0}
                    >
                      <path
                        id="SvgjsPath2584"
                        d="M -6.473600000000001 60.001 L -6.473600000000001 49.88335294117646 C -6.473600000000001 47.88335294117646 -4.473600000000001 45.88335294117646 -2.4736000000000016 45.88335294117646 L 2.473600000000001 45.88335294117646 C 4.473600000000001 45.88335294117646 6.473600000000001 47.88335294117646 6.473600000000001 49.88335294117646 L 6.473600000000001 60.001 z "
                        fill="rgba(32, 183, 87, 0.30)"
                        fillOpacity={1}
                        strokeOpacity={1}
                        strokeLinecap="round"
                        strokeWidth={0}
                        strokeDasharray={0}
                        className="apexcharts-bar-area"
                        index={0}
                        clipPath="url(#gridRectMask2os1p547)"
                        pathto="M -6.473600000000001 60.001 L -6.473600000000001 49.88335294117646 C -6.473600000000001 47.88335294117646 -4.473600000000001 45.88335294117646 -2.4736000000000016 45.88335294117646 L 2.473600000000001 45.88335294117646 C 4.473600000000001 45.88335294117646 6.473600000000001 47.88335294117646 6.473600000000001 49.88335294117646 L 6.473600000000001 60.001 z "
                        pathfrom="M -6.473600000000001 60.001 L -6.473600000000001 60.001 L 6.473600000000001 60.001 L 6.473600000000001 60.001 L 6.473600000000001 60.001 L 6.473600000000001 60.001 L 6.473600000000001 60.001 L -6.473600000000001 60.001 z"
                        cy="45.882352941176464"
                        cx="6.473600000000001"
                        // j={0}
                        val={8}
                        barheight="14.117647058823533"
                        barwidth="12.947200000000002"
                      />
                      <path
                        id="SvgjsPath2586"
                        d="M 12.022400000000001 60.001 L 12.022400000000001 35.76570588235293 C 12.022400000000001 33.76570588235293 14.022400000000001 31.765705882352936 16.0224 31.765705882352936 L 20.969600000000003 31.765705882352936 C 22.969600000000003 31.765705882352936 24.969600000000003 33.76570588235293 24.969600000000003 35.76570588235293 L 24.969600000000003 60.001 z "
                        fill="rgba(32, 183, 87, 0.30)"
                        fillOpacity={1}
                        strokeOpacity={1}
                        strokeLinecap="round"
                        strokeWidth={0}
                        strokeDasharray={0}
                        className="apexcharts-bar-area"
                        index={0}
                        clipPath="url(#gridRectMask2os1p547)"
                        pathto="M 12.022400000000001 60.001 L 12.022400000000001 35.76570588235293 C 12.022400000000001 33.76570588235293 14.022400000000001 31.765705882352936 16.0224 31.765705882352936 L 20.969600000000003 31.765705882352936 C 22.969600000000003 31.765705882352936 24.969600000000003 33.76570588235293 24.969600000000003 35.76570588235293 L 24.969600000000003 60.001 z "
                        pathfrom="M 12.022400000000001 60.001 L 12.022400000000001 60.001 L 24.969600000000003 60.001 L 24.969600000000003 60.001 L 24.969600000000003 60.001 L 24.969600000000003 60.001 L 24.969600000000003 60.001 L 12.022400000000001 60.001 z"
                        cy="31.764705882352935"
                        cx="24.969600000000003"
                        // j={1}
                        val={16}
                        barheight="28.235294117647065"
                        barwidth="12.947200000000002"
                      />
                      <path
                        id="SvgjsPath2588"
                        d="M 30.518400000000003 60.001 L 30.518400000000003 42.82452941176469 C 30.518400000000003 40.82452941176469 32.5184 38.82452941176469 34.5184 38.82452941176469 L 39.46560000000001 38.82452941176469 C 41.46560000000001 38.82452941176469 43.46560000000001 40.82452941176469 43.46560000000001 42.82452941176469 L 43.46560000000001 60.001 z "
                        fill="rgba(32, 183, 87, 0.30)"
                        fillOpacity={1}
                        strokeOpacity={1}
                        strokeLinecap="round"
                        strokeWidth={0}
                        strokeDasharray={0}
                        className="apexcharts-bar-area"
                        index={0}
                        clipPath="url(#gridRectMask2os1p547)"
                        pathto="M 30.518400000000003 60.001 L 30.518400000000003 42.82452941176469 C 30.518400000000003 40.82452941176469 32.5184 38.82452941176469 34.5184 38.82452941176469 L 39.46560000000001 38.82452941176469 C 41.46560000000001 38.82452941176469 43.46560000000001 40.82452941176469 43.46560000000001 42.82452941176469 L 43.46560000000001 60.001 z "
                        pathfrom="M 30.518400000000003 60.001 L 30.518400000000003 60.001 L 43.46560000000001 60.001 L 43.46560000000001 60.001 L 43.46560000000001 60.001 L 43.46560000000001 60.001 L 43.46560000000001 60.001 L 30.518400000000003 60.001 z"
                        cy="38.823529411764696"
                        cx="43.46560000000001"
                        // j={2}
                        val={12}
                        barheight="21.1764705882353"
                        barwidth="12.947200000000002"
                      />
                      <path
                        id="SvgjsPath2590"
                        d="M 49.01440000000001 60.001 L 49.01440000000001 4.000999999999986 C 49.01440000000001 2.000999999999986 51.01440000000001 0.0009999999999857892 53.01440000000001 0.0009999999999857892 L 57.96160000000001 0.0009999999999857892 C 59.96160000000001 0.0009999999999857892 61.96160000000001 2.0009999999999857 61.96160000000001 4.000999999999986 L 61.96160000000001 60.001 z "
                        fill="rgba(32, 183, 87, 0.30)"
                        fillOpacity={1}
                        strokeOpacity={1}
                        strokeLinecap="round"
                        strokeWidth={0}
                        strokeDasharray={0}
                        className="apexcharts-bar-area"
                        index={0}
                        clipPath="url(#gridRectMask2os1p547)"
                        pathto="M 49.01440000000001 60.001 L 49.01440000000001 4.000999999999986 C 49.01440000000001 2.000999999999986 51.01440000000001 0.0009999999999857892 53.01440000000001 0.0009999999999857892 L 57.96160000000001 0.0009999999999857892 C 59.96160000000001 0.0009999999999857892 61.96160000000001 2.0009999999999857 61.96160000000001 4.000999999999986 L 61.96160000000001 60.001 z "
                        pathfrom="M 49.01440000000001 60.001 L 49.01440000000001 60.001 L 61.96160000000001 60.001 L 61.96160000000001 60.001 L 61.96160000000001 60.001 L 61.96160000000001 60.001 L 61.96160000000001 60.001 L 49.01440000000001 60.001 z"
                        cy="-1.4210854715202004e-14"
                        cx="61.96160000000001"
                        // j={3}
                        val={34}
                        barheight="60.000000000000014"
                        barwidth="12.947200000000002"
                      />
                      <path
                        id="SvgjsPath2592"
                        d="M 67.5104 60.001 L 67.5104 25.177470588235284 C 67.5104 23.177470588235284 69.5104 21.177470588235284 71.5104 21.177470588235284 L 76.45760000000001 21.177470588235284 C 78.45760000000001 21.177470588235284 80.45760000000001 23.177470588235284 80.45760000000001 25.177470588235284 L 80.45760000000001 60.001 z "
                        fill="rgba(32, 183, 87, 0.30)"
                        fillOpacity={1}
                        strokeOpacity={1}
                        strokeLinecap="round"
                        strokeWidth={0}
                        strokeDasharray={0}
                        className="apexcharts-bar-area"
                        index={0}
                        clipPath="url(#gridRectMask2os1p547)"
                        pathto="M 67.5104 60.001 L 67.5104 25.177470588235284 C 67.5104 23.177470588235284 69.5104 21.177470588235284 71.5104 21.177470588235284 L 76.45760000000001 21.177470588235284 C 78.45760000000001 21.177470588235284 80.45760000000001 23.177470588235284 80.45760000000001 25.177470588235284 L 80.45760000000001 60.001 z "
                        pathfrom="M 67.5104 60.001 L 67.5104 60.001 L 80.45760000000001 60.001 L 80.45760000000001 60.001 L 80.45760000000001 60.001 L 80.45760000000001 60.001 L 80.45760000000001 60.001 L 67.5104 60.001 z"
                        cy="21.176470588235283"
                        cx="80.45760000000001"
                        // j={4}
                        val={22}
                        barheight="38.82352941176472"
                        barwidth="12.947200000000002"
                      />
                      <path
                        id="SvgjsPath2594"
                        d="M 86.0064 60.001 L 86.0064 32.23629411764705 C 86.0064 30.23629411764705 88.0064 28.236294117647052 90.0064 28.236294117647052 L 94.9536 28.236294117647052 C 96.9536 28.236294117647052 98.9536 30.23629411764705 98.9536 32.23629411764705 L 98.9536 60.001 z "
                        fill="rgba(32, 183, 87, 0.30)"
                        fillOpacity={1}
                        strokeOpacity={1}
                        strokeLinecap="round"
                        strokeWidth={0}
                        strokeDasharray={0}
                        className="apexcharts-bar-area"
                        index={0}
                        clipPath="url(#gridRectMask2os1p547)"
                        pathto="M 86.0064 60.001 L 86.0064 32.23629411764705 C 86.0064 30.23629411764705 88.0064 28.236294117647052 90.0064 28.236294117647052 L 94.9536 28.236294117647052 C 96.9536 28.236294117647052 98.9536 30.23629411764705 98.9536 32.23629411764705 L 98.9536 60.001 z "
                        pathfrom="M 86.0064 60.001 L 86.0064 60.001 L 98.9536 60.001 L 98.9536 60.001 L 98.9536 60.001 L 98.9536 60.001 L 98.9536 60.001 L 86.0064 60.001 z"
                        cy="28.23529411764705"
                        cx="98.9536"
                        // j={5}
                        val={18}
                        barheight="31.76470588235295"
                        barwidth="12.947200000000002"
                      />
                      <g
                        id="SvgjsG2581"
                        className="apexcharts-bar-goals-markers"
                      >
                        <g
                          id="SvgjsG2583"
                          // className="apexcharts-bar-goals-groups"
                          className="apexcharts-hidden-element-shown"
                          clipPath="url(#gridRectMarkerMask2os1p547)"
                        />
                        <g
                          id="SvgjsG2585"
                          // className="apexcharts-bar-goals-groups"
                          className="apexcharts-hidden-element-shown"
                          clipPath="url(#gridRectMarkerMask2os1p547)"
                        />
                        <g
                          id="SvgjsG2587"
                          // className="apexcharts-bar-goals-groups"
                          className="apexcharts-hidden-element-shown"
                          clipPath="url(#gridRectMarkerMask2os1p547)"
                        />
                        <g
                          id="SvgjsG2589"
                          // className="apexcharts-bar-goals-groups"
                          className="apexcharts-hidden-element-shown"
                          clipPath="url(#gridRectMarkerMask2os1p547)"
                        />
                        <g
                          id="SvgjsG2591"
                          // className="apexcharts-bar-goals-groups"
                          className="apexcharts-hidden-element-shown"
                          clipPath="url(#gridRectMarkerMask2os1p547)"
                        />
                        <g
                          id="SvgjsG2593"
                          // className="apexcharts-bar-goals-groups"
                          className="apexcharts-hidden-element-shown"
                          clipPath="url(#gridRectMarkerMask2os1p547)"
                        />
                      </g>
                      <g
                        id="SvgjsG2582"
                        className="apexcharts-bar-shadows apexcharts-hidden-element-shown"
                      />
                    </g>
                    <g
                      id="SvgjsG2580"
                      className="apexcharts-datalabels apexcharts-hidden-element-shown"
                      // data:realindex={0}
                    />
                  </g>
                  <line
                    id="SvgjsLine2612"
                    x1="-16.759999999999998"
                    y1={0}
                    x2="109.24000000000001"
                    y2={0}
                    stroke="#b6b6b6"
                    strokeDasharray={0}
                    strokeWidth={1}
                    strokeLinecap="butt"
                    className="apexcharts-ycrosshairs"
                  />
                  <line
                    id="SvgjsLine2613"
                    x1="-16.759999999999998"
                    y1={0}
                    x2="109.24000000000001"
                    y2={0}
                    strokeDasharray={0}
                    strokeWidth={0}
                    strokeLinecap="butt"
                    className="apexcharts-ycrosshairs-hidden"
                  />
                  <g
                    id="SvgjsG2614"
                    className="apexcharts-xaxis"
                    transform="translate(0, 0)"
                  >
                    <g
                      id="SvgjsG2615"
                      className="apexcharts-xaxis-texts-g"
                      transform="translate(0, -4)"
                    />
                  </g>
                  <g id="SvgjsG2623" className="apexcharts-yaxis-annotations" />
                  <g id="SvgjsG2624" className="apexcharts-xaxis-annotations" />
                  <g id="SvgjsG2625" className="apexcharts-point-annotations" />
                </g>
              </svg>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FinancialStatCard;
