import React, { useEffect, useState } from "react";
import { useAuth } from "../hooks";
import TransactionAnalytics from "../pages/cashier/TransactionAnalytics";
import { FiPlusCircle } from "react-icons/fi";
import Title from "../form/Title";
import FinancialStatCard from "../pages/cashier/FinacialStatCard";
import {
  fetchTransactionAnalytics,
  getSpendingGoals,
} from "../../api/cashier/cashier";
import {
  formatCurrency,
  formatCurrencyNew,
  getCurrencyIcon,
} from "../../utils/helper";
import ModalContainer from "../models/ModalContainer";
import SpendingGoalForm from "../form/SpendingGoalForm";
import Chart from "react-apexcharts";
import { transactionStats } from "../../api/transactions";
import SpendingGoalList from "../pages/cashier/SpendingGoalList";
import DepositsWithdrawalsChart from "../pages/DepositsWithdrawalsChart";
import AccountsTable from "../pages/cashier/AccountsTable";
import TransactionsTable from "../pages/cashier/TransactionsTable";
import { useNavigate } from "react-router-dom";
import { BiMoneyWithdraw } from "react-icons/bi";
import { RiLuggageDepositFill } from "react-icons/ri";
import { MdManageAccounts } from "react-icons/md";
import FiveRecentTransactions from "../pages/cashier/FiveRecentTransactions";

const CashierDashboard = () => {
  const [showGoalModal, setShowGoalModal] = useState(false);
  const { authInfo } = useAuth();
  const { isLoggedIn } = authInfo;
  const userId = authInfo?.profile?.id;
  const [currencyIcon, setCurrencyIcon] = useState("");
  const [currencyCode, setCurrencyCode] = useState("");
  const [spendingGoals, setSpendingGoals] = useState([]);

  const [summary, setSummary] = useState({
    totalBalance: 0,
    totalAmount: 0,
    totalSpending: 0,
    totalTransactions: 0,
    spendingGoals: [],
    percentages: {
      totalAmount: 0,
      totalSpending: 0,
      totalTransactions: 0,
    },
  });

  const [data, setData] = useState([]);
  const [labels, setLabels] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await transactionStats();
        const transactionData = response;
        setData(transactionData?.map((item) => parseFloat(item.percentage)));
        setLabels(transactionData?.map((item) => item.status));
      } catch (error) {
        console.error("Error fetching transaction stats:", error);
      }
    };

    fetchData();
  }, []);

  const options = {
    chart: {
      type: "donut",
    },
    labels: labels,
    dataLabels: {
      enabled: false,
    },
    plotOptions: {
      pie: {
        donut: {
          size: "70%",
          labels: {
            show: true,
            name: {
              show: true,
              offsetY: -10,
              formatter: function (val) {
                return val;
              },
            },
            value: {
              show: true,
              offsetY: 10,
              formatter: function (val) {
                return val + "%";
              },
            },
            total: {
              show: true,
              label: "Bank",
              fontSize: "18px",
              color: "#000",
              formatter: function () {
                return "Transactions";
              },
            },
          },
        },
      },
    },
    colors: [
      "rgba(255,97,97,1)",
      "#FEB019",
      "rgba(32,183,87,1)",
      "#775DD0",
      "#FF63A5",
    ],
    legend: {
      show: true,
      position: "bottom",
    },
  };

  const hideGoalModal = () => {
    setShowGoalModal(false);
  };
  const handleToggleModal = () => {
    setShowGoalModal((prev) => !prev);
  };

  useEffect(() => {
    const fetchStats = async () => {
      try {
        const response = await fetchTransactionAnalytics(userId);
        const data = response;

        // Adjust percentages to avoid 100% or 0%
        const adjustedPercentages = {
          totalAmount: Math.max(1, Math.min(100, data.percentages.totalAmount)),
          totalSpending: Math.max(
            0,
            Math.min(100, data.percentages.totalSpending)
          ),
          totalTransactions: Math.max(
            0,
            Math.min(100, data.percentages.totalTransactions)
          ),
        };

        setSummary({ ...data, percentages: adjustedPercentages });
      } catch (error) {
        console.error("Error fetching cashier stats:", error);
      }
    };

    fetchStats();
  }, [userId]);

  const fetchCurrencyIcon = async () => {
    try {
      const data = await getCurrencyIcon();
      setCurrencyIcon(data?.currencyIcon);
      setCurrencyCode(data?.currencyCode);
    } catch (error) {
      console.error("Error fetching currency icon:", error);
    }
  };

  useEffect(() => {
    fetchCurrencyIcon();
  }, []);

  useEffect(() => {
    const fetchSpendingGoals = async () => {
      try {
        const response = await getSpendingGoals(userId);
        setSpendingGoals(response);
      } catch (error) {
        console.error("Error fetching spending goals:", error);
      }
    };

    fetchSpendingGoals();
  }, [userId]);

  const handleGoalCreated = (newGoal) => {
    setSummary((prevSummary) => ({
      ...prevSummary,
      spendingGoals: [...prevSummary.spendingGoals, newGoal],
    }));
    setSpendingGoals((prevGoals) => [...prevGoals, newGoal]);
  };

  // Calculate total goal amount
  const totalGoalAmount = summary?.spendingGoals?.reduce(
    (acc, goal) => acc + goal?.goalAmount,
    0
  );

  const totalBalance = formatCurrency(
    summary?.totalBalance === 0 ? 0 : summary?.totalBalance,
    currencyIcon,
    currencyCode
  );

  if (!summary) {
    return <div>Loading...</div>;
  }

  const withdraw = () => {
    navigate("/finance/withdrawal-requests");
  };
  const deposit = () => {
    navigate("/finance/deposit-transactions");
  };
  const profile = () => {
    navigate("/profile");
  };

  return (
    <>
      <div className="dark:bg-primary bg-white min-h-screen">
        <div className={"mt-10"}>
          {/* <Slider /> */}
          <div className="flex flex-col h-screen justify-between">
            <div className="mt-5 h-full">
              <main className="col-span-12 pb-16 mx-5" id="tour-0">
                <section aria-labelledby="profile-overview-title">
                  <div className="px-3 relative sm:px-4 xxxl:px-6 py-6 lg:py-8 duration-300 false false">
                    <div className="false false">
                      <div className="flex justify-between flex-wrap items-center gap-4 mb-6 xl:mb-8">
                        <div>
                          <Title className="mb-1">{totalBalance}</Title>
                          <p>
                            Total Balance from all accounts{" "}
                            <span className="text-primary font-semibold">
                              {currencyIcon}
                            </span>
                          </p>
                        </div>
                        <div className="flex justify-between flex-wrap items-center gap-4 mb-0 lg:mb-0">
                          <h2 className="h2" />
                          <button className="btn" onClick={withdraw}>
                            <BiMoneyWithdraw className="flex items-center text-center text-base md:text-lg" />
                            withdrawal
                          </button>
                          <button className="btn" onClick={deposit}>
                            <RiLuggageDepositFill className="flex items-center text-center text-base md:text-lg" />
                            deposit
                          </button>
                          <button className="btn" onClick={profile}>
                            <MdManageAccounts className="flex items-center text-center text-base md:text-lg" />
                            Profile
                          </button>
                        </div>
                      </div>
                      <div className="grid grid-cols-12 gap-4 xxl:gap-6">
                        <div className="col-span-12 lg:col-span-8">
                          <div className="grid grid-cols-2 gap-4 xxl:gap-6 mb-4 xxl:mb-6">
                            <FinancialStatCard
                              title="My Balance"
                              value={summary?.totalAmount}
                              percentage={summary?.percentages?.totalAmount}
                              isPositive={
                                summary?.percentages?.totalAmount >= 40
                              }
                            />
                            <FinancialStatCard
                              title="Total Spending"
                              value={summary?.totalSpending}
                              percentage={summary?.percentages?.totalSpending}
                              isPositive={
                                summary?.percentages?.totalSpending >= 60
                              }
                            />
                            <FinancialStatCard
                              title="Spending Goal"
                              value={totalGoalAmount}
                              percentage={0}
                              isPositive={true}
                              spendingGoal={true}
                              handleGoalModal={handleToggleModal}
                            />
                            <FinancialStatCard
                              title="Total Transactions"
                              value={summary?.totalTransactions}
                              percentage={
                                summary?.percentages?.totalTransactions
                              }
                              isPositive={
                                summary?.percentages?.totalTransactions >= 50
                              }
                            />
                          </div>
                          <div className="box">
                            <DepositsWithdrawalsChart />
                          </div>
                          <div className="box">
                            <AccountsTable
                              formatCurrency={formatCurrency}
                              currencyCode={currencyCode}
                              currencyIcon={currencyIcon}
                            />
                          </div>
                        </div>
                        <div className="col-span-12 lg:col-span-4">
                          <div className="box mb-4 xxl:mb-6">
                            <div className="flex flex-wrap justify-between items-center gap-3 pb-4 lg:pb-6 mb-4 lg:mb-6 bb-dashed">
                              <p className="font-medium">Weekly Transactions</p>
                              <div id="chart">
                                <Chart
                                  options={options}
                                  series={data}
                                  type="donut"
                                  height="350"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="box mb-4 xxl:mb-6">
                            <FiveRecentTransactions
                              formatCurrency={formatCurrency}
                              currencyCode={currencyCode}
                              currencyIcon={currencyIcon}
                            />
                          </div>
                          <div className="box">
                            <TransactionsTable
                              formatCurrency={formatCurrency}
                              currencyCode={currencyCode}
                              currencyIcon={currencyIcon}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div>
                    <SpendingGoalList
                      userId={userId}
                      spendingGoals={spendingGoals}
                      setSpendingGoals={setSpendingGoals}
                    />
                    <TransactionAnalytics />
                  </div>
                  <div className="col-span-12 lg:col-span-4">
                    {showGoalModal && (
                      <ModalContainer
                        visible={showGoalModal}
                        onClose={hideGoalModal}
                        ignoreContainer
                      >
                        <SpendingGoalForm
                          userId={userId}
                          onGoalCreated={handleGoalCreated}
                        />
                      </ModalContainer>
                    )}
                  </div>
                </section>
              </main>
            </div>
            <div className="m-5 admin_footer"></div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CashierDashboard;
