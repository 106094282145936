import React from "react";
import "../css/bank.css";

export const Bank = () => {
  return (
    <div>
      <div className="topbar-style-1">
        <div className="container">
          <div className="flex flex-row justify-center">
            <div className="lg:w-1/2 md:w-1/2 w-full">
              <div className="left-side">
                <div className="language-box">
                  <div className="language p-relative">
                    <img
                      src="/templatemonster/react/fundnly/assets/images/flag.png"
                      alt="flag"
                    />
                    <select>
                      <option>English</option>
                      <option>German</option>
                      <option>Japanese</option>
                    </select>
                  </div>
                </div>
                <p className="text-custom text-white m-0">
                  9000 Regency Parkway, Suite 400 Cary
                </p>
              </div>
            </div>
            <div className="lg:w-1/2 md:w-1/2 w-full">
              <div className="right-side">
                <ul className="custom">
                  <li>
                    <a
                      className="text-custom text-white text-base"
                      href="/templatemonster/react/fundnly/"
                    >
                      <i className="fab fa-facebook-f" />
                    </a>
                  </li>
                  <li>
                    <a
                      className="text-custom text-white text-base"
                      href="/templatemonster/react/fundnly/"
                    >
                      <i className="fab fa-twitter" />
                    </a>
                  </li>
                  <li>
                    <a
                      className="text-custom text-white text-base"
                      href="/templatemonster/react/fundnly/"
                    >
                      <i className="fab fa-linkedin" />
                    </a>
                  </li>
                  <li>
                    <a
                      className="text-custom text-white text-base"
                      href="/templatemonster/react/fundnly/"
                    >
                      <i className="fab fa-youtube" />
                    </a>
                  </li>
                  <li className="search">
                    <a
                      className="text-custom text-white text-base"
                      href="/templatemonster/react/fundnly/"
                    >
                      <i className="fas fa-search" />
                    </a>
                  </li>
                  <li className="topbar-search">
                    <form method="get">
                      <input
                        type="search"
                        className="form-control form-control-custom"
                        name="search"
                        placeholder="Search..."
                        spellCheck="false"
                        data-ms-editor="true"
                      />
                    </form>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="main-navigation-style-1">
        <div className="container">
          <div className="flex flex-row">
            <div className="w-full">
              <div className="navigation">
                <div className="logo">
                  <a href="/templatemonster/react/fundnly/">
                    <img
                      src="/templatemonster/react/fundnly/assets/images/logo.png"
                      className="img-fluid image-fit"
                      alt="logo"
                    />
                  </a>
                </div>
                <div className="main-menu">
                  <div className="mobile-logo">
                    <a href="/templatemonster/react/fundnly/">
                      <img
                        src="/templatemonster/react/fundnly/assets/images/logo.png"
                        className="img-fluid image-fit"
                        alt="logo"
                      />
                    </a>
                  </div>
                  <nav>
                    <ul className="flex flex-col">
                      <li className="menu-item relative group">
                        <a
                          className="text-white"
                          href="/templatemonster/react/fundnly/"
                        >
                          Homepages
                        </a>
                        <ul className="sub-menu absolute hidden group-hover:block">
                          <li className="menu-item">
                            <a
                              className="text-gray-500"
                              href="/templatemonster/react/fundnly/"
                            >
                              Homepage 1
                            </a>
                          </li>
                          <li className="menu-item">
                            <a
                              className="text-gray-500"
                              href="/templatemonster/react/fundnly/home-v2"
                            >
                              Homepage 2
                            </a>
                          </li>
                        </ul>
                      </li>
                      <li className="menu-item">
                        <a
                          className="text-white"
                          href="/templatemonster/react/fundnly/finance"
                        >
                          Finance
                        </a>
                      </li>
                      <li className="menu-item relative group">
                        <a
                          className="text-white"
                          href="/templatemonster/react/fundnly/"
                        >
                          Blog
                        </a>
                        <ul className="sub-menu absolute hidden group-hover:block">
                          <li className="menu-item">
                            <a
                              className="text-gray-500"
                              href="/templatemonster/react/fundnly/blog"
                            >
                              Blog Grid
                            </a>
                          </li>
                          <li className="menu-item">
                            <a
                              className="text-gray-500"
                              href="/templatemonster/react/fundnly/blog-sidebar"
                            >
                              Blog Left
                            </a>
                          </li>
                          <li className="menu-item">
                            <a
                              className="text-gray-500"
                              href="/templatemonster/react/fundnly/blog-single/1"
                            >
                              Blog Details
                            </a>
                          </li>
                        </ul>
                      </li>
                      <li className="menu-item relative group">
                        <a
                          className="text-white"
                          href="/templatemonster/react/fundnly/"
                        >
                          Pages
                        </a>
                        <ul className="sub-menu absolute hidden group-hover:block">
                          <li className="menu-item">
                            <a
                              className="text-gray-500"
                              href="/templatemonster/react/fundnly/error"
                            >
                              404
                            </a>
                          </li>
                          <li className="menu-item">
                            <a
                              className="text-gray-500"
                              href="/templatemonster/react/fundnly/coming-soon"
                            >
                              Coming Soon
                            </a>
                          </li>
                          <li className="menu-item">
                            <a
                              className="text-gray-500"
                              href="/templatemonster/react/fundnly/team"
                            >
                              Our Team
                            </a>
                          </li>
                          <li className="menu-item">
                            <a
                              className="text-gray-500"
                              href="/templatemonster/react/fundnly/loan-calculator"
                            >
                              Loan Calculator
                            </a>
                          </li>
                          <li className="menu-item">
                            <a
                              className="text-gray-500"
                              href="/templatemonster/react/fundnly/about"
                            >
                              About Us
                            </a>
                          </li>
                          <li className="menu-item">
                            <a
                              className="text-gray-500"
                              href="/templatemonster/react/fundnly/contact"
                            >
                              Contact Us
                            </a>
                          </li>
                        </ul>
                      </li>
                      <li className="menu-item relative group">
                        <a
                          className="text-white"
                          href="/templatemonster/react/fundnly/"
                        >
                          Services
                        </a>
                        <ul className="sub-menu absolute hidden group-hover:block">
                          <li className="menu-item">
                            <a
                              className="text-gray-500"
                              href="/templatemonster/react/fundnly/service"
                            >
                              Service
                            </a>
                          </li>
                          <li className="menu-item">
                            <a
                              className="text-gray-500"
                              href="/templatemonster/react/fundnly/service-detail/1"
                            >
                              Service Detail
                            </a>
                          </li>
                        </ul>
                      </li>
                      <li className="menu-item">
                        <a
                          className="text-white"
                          href="/templatemonster/react/fundnly/loan-dashboard"
                        >
                          User Dashboard
                        </a>
                      </li>
                    </ul>
                  </nav>
                  <div className="right-side">
                    <div className="cta-btn">
                      <a
                        className="btn-first btn-submit"
                        href="/templatemonster/react/fundnly/finance"
                      >
                        Apply Today
                      </a>
                    </div>
                  </div>
                </div>
                <div className="hamburger-menu">
                  <div className="menu-btn">
                    <span></span>
                    <span></span>
                    <span></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
