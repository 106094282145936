import React, { useState, useEffect } from "react";
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableContainer,
  Paper,
  Button,
  TextField,
  TablePagination,
} from "@mui/material";
import axios from "axios";
import Title from "../../form/Title";
import {
  formatCurrency,
  getCurrencyIcon,
  getToken,
} from "../../../utils/helper";
import client from "../../../api/client";

const TransactionAnalytics = () => {
  const [transactions, setTransactions] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [loading, setLoading] = useState(false);
  const [currencyIcon, setCurrencyIcon] = useState("");
  const [currencyCode, setCurrencyCode] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  useEffect(() => {
    const fetchCurrencyIcon = async () => {
      try {
        setLoading(true);
        const data = await getCurrencyIcon();
        setCurrencyIcon(data?.currencyIcon);
        setCurrencyCode(data?.currencyCode);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.error("Error fetching currency icon:", error);
      }
    };

    fetchCurrencyIcon();
  }, []);

  useEffect(() => {
    fetchTransactions();
  }, []);

  const fetchTransactions = async () => {
    try {
      const token = getToken();
      const response = await client.get("/transactions/analytics", {
        params: { startDate, endDate },
        headers: {
          authorization: "Bearer " + token,
        },
      });
      setTransactions(response.data.transactions);
    } catch (error) {
      console.error("Failed to fetch transaction analytics:", error);
    }
  };

  const handleDateRangeSubmit = () => {
    fetchTransactions();
  };

  const filteredDatas = transactions?.filter((dat) =>
    dat?.transactionId?.toLowerCase()?.includes(searchTerm?.toLowerCase())
  );

  const paginatedDatas = filteredDatas?.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event?.target?.value, 10));
    setPage(0);
  };

  //   console.log(transactions);

  return (
    <div className="my-10 mx-10">
      <div>
        <Title>Transaction Analytics</Title>
      </div>
      <div className="flex w-full my-3">
        <div className="flex mx-5 my-3 justify-around">
          <div className="mr-3">
            <TextField
              id="startDate"
              label="Start Date"
              type="date"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </div>
          <div className="ml-3">
            <TextField
              id="endDate"
              label="End Date"
              type="date"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </div>
        </div>
        <div className="w-full my-3">
          <Button
            variant="contained"
            color="primary"
            onClick={handleDateRangeSubmit}
          >
            Filter
          </Button>
        </div>
      </div>
      <TextField
        label="Search (transaction ID)"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        className="!bg-white"
      />
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>User</TableCell>
              <TableCell>Transaction ID</TableCell>
              <TableCell>Amount</TableCell>
              <TableCell>Type</TableCell>
              <TableCell>Date</TableCell>
              {/* Add more columns as needed */}
            </TableRow>
          </TableHead>
          <TableBody>
            {paginatedDatas?.map((transaction, index) => {
              const user =
                transaction?.accountId?.userId?.first_name +
                " " +
                transaction?.accountId?.userId?.last_name;
              return (
                <TableRow key={index}>
                  <TableCell>{user}</TableCell>
                  <TableCell>{transaction.transactionId}</TableCell>
                  <TableCell>
                    {formatCurrency(
                      transaction?.amount === 0 ? 0 : transaction?.amount,
                      currencyIcon,
                      currencyCode
                    )}
                  </TableCell>
                  <TableCell>{transaction.type || "transaction"}</TableCell>
                  <TableCell>
                    {new Date(transaction.createdAt)?.toLocaleDateString()}
                  </TableCell>
                  {/* Render additional columns */}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={filteredDatas?.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        className="dark:!text-white"
      />
    </div>
  );
};

export default TransactionAnalytics;
