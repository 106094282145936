import React from "react";
import { useAuth } from "../hooks";
import useConversation from "./zustand/useConversation";
import { extractTime } from "../../utils/extractTime";

const Message = ({ message }) => {
  const { authInfo } = useAuth();
  const { selectedConversation } = useConversation();
  const fromMe = message?.senderId === authInfo?.profile?.id;
  const formattedTime = extractTime(message?.createdAt);
  const chatClassName = fromMe ? "chat-end" : "chat-start";
  const profilePic = fromMe
    ? authInfo?.profile?.picture
    : selectedConversation?.picture;
  const bubbleBgColor = fromMe ? "bg-blue-500" : "";

  return (
    <div className={`chat ${chatClassName}`}>
      <div className="chat-image avatar">
        <div className="w-10 rounded-full">
          <img src={profilePic} alt="User Chat" />
        </div>
      </div>
      <div
        className={`chat-bubble text-white bg-blue-500 ${bubbleBgColor} pb-1`}
      >
        {message?.message}
      </div>
      <div className="chat-footer opacity-50 text-xs flex gap-1 items-center text-gray-700">
        {formattedTime}
        {selectedConversation && message?.status === "seen" ? (
          <img
            src="/double-check.png"
            className="chat-footer opacity-50 text-xs flex gap-1 w-6 h-6"
          />
        ) : (
          <span className="chat-footer opacity-50 text-xs">✔️</span>
        )}
      </div>
    </div>
  );
};

export default Message;
