import React from "react";
import { Layout, Space } from "antd";
import { Link } from "react-router-dom";
import { useAuth } from "../hooks";
import { CiLogout } from "react-icons/ci";

const { Header } = Layout;

const MarketerHeader = () => {
  const { authInfo, handleLogout } = useAuth();

  return (
    <div>
      <Layout>
        <Header
          className="z-10 w-full bg-white"
          style={{
            height: "60px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "0 20px",
            borderBottom: "1px solid #e8e8e8",
          }}
        >
          <div className="logo" />
          <Space>
            <div className="text-white">
              {authInfo?.profile?.branch?.location}
            </div>
          </Space>

          <Space>
            <Link
              to="/auth/signin"
              onClick={handleLogout}
              className="flex items-center text-white text-sm hover:dark:!text-white hover:!text-blue-500 transition space-x-1 ml-10 bg-red-500 rounded-lg px-2"
            >
              <CiLogout />
              <span className="whitespace-nowrap">Log Out</span>
            </Link>
          </Space>
        </Header>
      </Layout>
    </div>
  );
};

export default MarketerHeader;
