import axios from "axios";
import { catchError } from "../../utils/helper";
import client from "../client";

export const getFooter = async () => {
  try {
    const { data } = await client.get("/footer");
    return data;
  } catch (error) {
    return catchError(error);
  }
};
