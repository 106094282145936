import React, { useState, useEffect } from "react";
import ReactApexChart from "react-apexcharts";
import axios from "axios";
import { getToken } from "../../utils/helper";
import client from "../../api/client";

const DepositsWithdrawalsChart = () => {
  const [depositData, setDepositData] = useState([]);
  const [withdrawalData, setWithdrawalData] = useState([]);
  const [dates, setDates] = useState([]);
  const [timeFrame, setTimeFrame] = useState("last_7_days");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = getToken();
        const response = await client.get(
          "/transactions/deposits-withdrawals",
          {
            params: { timeFrame },
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const deposits = response?.data?.deposits?.map((item) => item?.amount);
        const withdrawals = response?.data?.withdrawals?.map(
          (item) => item.amount
        );
        const dates = response?.data?.deposits?.map((item) => item?.date);

        setDepositData(deposits);
        setWithdrawalData(withdrawals);
        setDates(dates);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [timeFrame]);

  const options = {
    chart: {
      type: "line",
      height: 350,
    },
    xaxis: {
      categories: dates,
    },
    colors: ["#00E396", "#FEB019"],
    legend: {
      show: true,
      position: "top",
    },
    stroke: {
      curve: "smooth",
    },
    title: {
      text: "Deposits and Withdrawals",
      align: "left",
    },
  };

  const series = [
    {
      name: "Deposits",
      data: depositData,
    },
    {
      name: "Withdrawals",
      data: withdrawalData,
    },
  ];

  return (
    <div className="col-span-12 lg:col-span-6 box overflow-x-hidden scrollbar mb-4 xxl:mb-6">
      <div className="flex flex-wrap justify-between items-center gap-3 pb-4 lg:pb-6 mb-4 lg:mb-6 border-b border-dashed">
        <div className="flex items-center gap-2">
          <label
            htmlFor="timeFrame"
            className="text-xs sm:text-sm md:text-base"
          >
            Sort By :{" "}
          </label>
          <div className="relative">
            <select
              id="timeFrame"
              value={timeFrame}
              onChange={(e) => setTimeFrame(e.target.value)}
              className="duration-300 border select-none cursor-pointer bg-primary/5 dark:bg-gray-400 border-green-500 text-sm md:text-base dark:border-n500 flex gap-2 justify-between items-center rounded-xl px-3 py-1.5 sm:px-4 sm:py-2"
            >
              <option
                className="px-4 py-2 cursor-pointer text-sm rounded-md duration-300 bg-primary/5 "
                value="last_7_days"
              >
                Last 7 Days
              </option>
              <option
                className="px-4 py-2 cursor-pointer text-sm rounded-md duration-300 false"
                value="last_15_days"
              >
                Last 15 Days
              </option>
              <option
                className="px-4 py-2 cursor-pointer text-sm rounded-md duration-300 false"
                value="last_1_month"
              >
                Last 1 Month
              </option>
            </select>
          </div>
        </div>
      </div>
      <div id="chart">
        <ReactApexChart
          options={options}
          series={series}
          type="line"
          height="350"
        />
      </div>
    </div>
  );
};

export default DepositsWithdrawalsChart;
