import React from "react";
import Sidebar from "./sidebar/Sidebar";
import MessageContainer from "./MessageContainer";

const Messenger = () => {
  return (
    <div className="flex sm:h-[450px] md:h-[550px] w-fit rounded-lg overflow-hidden !bg-gray-400 bg-clip-padding backdrop-filter backdrop-blur-lg bg-opacity-0 my-20 mx-20 py-2 px-2">
      <Sidebar />
      <MessageContainer />
    </div>
  );
};

export default Messenger;
