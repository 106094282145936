import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import ContextProviders from "./component/context";
import reportWebVitals from "./reportWebVitals";
import ThemeProvider from "./utils/ThemeContext";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import { SocketContextProvider } from "./component/context/SocketContext";
import { inject } from "@vercel/analytics";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <ContextProviders>
        <ThemeProvider>
          <SocketContextProvider>
            <App />
          </SocketContextProvider>
        </ThemeProvider>
      </ContextProviders>
    </BrowserRouter>
  </React.StrictMode>
);

serviceWorkerRegistration.register();
reportWebVitals();
inject();
