import { getToken } from "../utils/helper";
import client from "./client";
const token = getToken();

export const updateLogoAndFavicon = async (formData) => {
  try {
    const { data } = await client.post("/setting/update-settings", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        authorization: "Bearer " + token,
      },
    });

    return data;
  } catch (error) {
    return error.response.data;
  }
};
export const getLogoAndFavicon = async (request) => {
  try {
    const { data } = await client.get("/setting/logo-and-favicon", request, {
      headers: {
        authorization: "Bearer " + token,
      },
    });
    return data;
  } catch (error) {
    return error.response.data;
  }
};
