import React, { useState } from "react";
import { createSpendingGoal } from "../../api/cashier/cashier";
import { FormLabel } from "@mui/material";
import { useNotification } from "../hooks";

const SpendingGoalForm = ({ userId, onGoalCreated }) => {
  const [goalAmount, setGoalAmount] = useState("");
  const [note, setNote] = useState("");
  const [dueDate, setDueDate] = useState("");
  const [loading, setLoading] = useState(false);
  const { updateNotification } = useNotification();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      // Simulate a delay of 2-3 seconds
      await new Promise((resolve) => setTimeout(resolve, 2000));
      const response = await createSpendingGoal({
        userId,
        goalAmount: Number(goalAmount),
        dueDate,
        note,
      });
      onGoalCreated(response);
      updateNotification("success", "Goal Created Successfully!");
      setGoalAmount("");
      setDueDate("");
    } catch (error) {
      updateNotification("error", "Error creating spending goal!");
      console.error("Error creating spending goal:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="mb-6 bg-gray-700 p-20">
      <div className="mb-4">
        <FormLabel
          className="!text-white text-sm font-bold mb-2"
          htmlFor="goalAmount"
        >
          Goal Amount
        </FormLabel>
        <input
          type="number"
          id="goalAmount"
          value={goalAmount}
          onChange={(e) => setGoalAmount(e.target.value)}
          required
          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          disabled={loading}
        />
      </div>
      <div className="mb-4">
        <FormLabel
          className="!text-white text-sm font-bold mb-2"
          htmlFor="dueDate"
        >
          Due Date
        </FormLabel>
        <input
          type="date"
          id="dueDate"
          value={dueDate}
          onChange={(e) => setDueDate(e.target.value)}
          required
          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          disabled={loading}
        />
      </div>
      <div className="mb-4">
        <FormLabel
          className="!text-white text-sm font-bold mb-2"
          htmlFor="duenoteDate"
        >
          Note
        </FormLabel>
        <input
          type="text"
          id="note"
          value={note}
          onChange={(e) => setNote(e.target.value)}
          required
          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          disabled={loading}
        />
      </div>
      <button
        type="submit"
        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
        disabled={loading}
      >
        {loading ? "Creating Goal..." : "Create Goal"}
      </button>
    </form>
  );
};

export default SpendingGoalForm;
