import React, { useState } from "react";
import { Layout, Menu } from "antd";
import { Link } from "react-router-dom";
import { useAuth } from "../hooks";
import { CiLogout } from "react-icons/ci";
import { BsBank2 } from "react-icons/bs";
import { FaRegUser } from "react-icons/fa";

const { Sider } = Layout;

function getItem(label, key, icon, children) {
  return {
    key,
    icon,
    children,
    label,
  };
}

const items = [
  getItem("DASHBOARD", "1", <Link to="/" />),
  getItem("Customers", "sub1", <FaRegUser />, [
    getItem("All Customers", "2", <Link to="/marketer/customers" />),
  ]),
  getItem("Deposits", "sub2", <BsBank2 />, [
    getItem("Record Deposit", "3", <Link to="/marketer/deposit" />),
    getItem("History", "4", <Link to="/marketer/deposits" />),
  ]),
];

const MarketerNavbar = ({ onCollapseChange }) => {
  const { authInfo, handleLogout } = useAuth();
  const [collapsed, setCollapsed] = useState(false);

  const handleCollapse = (value) => {
    setCollapsed(value);
    onCollapseChange(value);
  };

  return (
    <div className="">
      <Layout
        style={{
          minHeight: "100vh",
        }}
      >
        <Sider
          collapsible
          collapsed={collapsed}
          onCollapse={handleCollapse}
          breakpoint="md"
          collapsedWidth="25"
          // trigger={null}
          style={{
            position: "fixed",
            height: "100vh",
            overflow: "auto",
            left: 0,
            top: 0,
            zIndex: 50,
          }}
        >
          <div
            style={{
              height: 32,
              margin: 16,
              background: "rgba(255, 255, 255, 0.2)",
              color: "green",
              fontWeight: "bold",
              textAlign: "center",
              fontSize: "20px",
            }}
          >
            <span className="text-green-500 uppercase">
              {authInfo?.profile?.branchRole || authInfo?.profile?.role}
            </span>
          </div>
          <div className="h-5/6 flex flex-col justify-between">
            <Menu
              mode="inline"
              theme="dark"
              defaultSelectedKeys={["1"]}
              items={items}
            />
            <Link
              to="/auth/signin"
              onClick={handleLogout}
              className="flex items-center text-gray-400 text-sm hover:dark:!text-white hover:!blue-500 transition space-x-1 ml-10"
            >
              <CiLogout />
              <span className="whitespace-nowrap">Log Out</span>
            </Link>
          </div>
        </Sider>
      </Layout>
    </div>
  );
};

export default MarketerNavbar;
