import React, { useState } from "react";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import Container from "../../containers/Container";
import FormInput from "../../form/FormInput";

const PasswordInput = ({
  placeholder,
  label,
  value,
  onChange,
  name,
  onPaste,
  className,
}) => {
  // state
  const [showPassword, setShowPassword] = useState(false);

  const togglePassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className="relative !w-full">
      <FormInput
        type={showPassword ? "text" : "password"}
        placeholder={placeholder}
        required
        label={label}
        name={name}
        value={value}
        onChange={onChange}
        onPaste={onPaste}
        className={className}
      />
      <div
        className=" absolute top-9 right-4 cursor-pointer"
        onClick={togglePassword}
      >
        {showPassword ? (
          <AiOutlineEyeInvisible size={20} className="passwordAI" />
        ) : (
          <AiOutlineEye size={20} className="passwordAI" />
        )}
      </div>
    </div>
  );
};

export default PasswordInput;
