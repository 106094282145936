import { catchError, getToken } from "../utils/helper";
import client from "./client";
const token = getToken();

export const createUser = async (userInfo) => {
  try {
    const { data } = await client.post("/users/create", userInfo);
    return data;
  } catch (error) {
    const { response } = error;
    if (response?.data) return response.data;

    return { error: error.message || error };
  }
};

export const verifyUserEmail = async (userInfo) => {
  try {
    const { data } = await client.post("/users/verify-email", userInfo);
    return data;
  } catch (error) {
    const { response } = error;
    if (response?.data) return response.data;

    return { error: error.message || error };
  }
};

export const signInUser = async (userInfo) => {
  try {
    const { data } = await client.post("/users/sign-in", userInfo);
    return data;
  } catch (error) {
    const { response } = error;
    if (response?.data) return response.data;

    return { error: error.message || error };
  }
};
export const signInManager = async (userInfo) => {
  try {
    const { data } = await client.post("/branch/sign-in", userInfo);
    return data;
  } catch (error) {
    const { response } = error;
    if (response?.data) return response.data;

    return { error: error.message || error };
  }
};

export const getIsAuth = async (token) => {
  try {
    const { data } = await client.get("/users/is-auth", {
      headers: {
        Authorization: "Bearer " + token,
        accept: "application/json",
      },
    });
    return data;
  } catch (error) {
    const { response } = error;
    if (response?.data) return response.data;

    return { error: error.message || error };
  }
};

export const forgetPassword = async (email) => {
  try {
    const { data } = await client.post("/users/forget-password", { email });
    return data;
  } catch (error) {
    const { response } = error;
    if (response?.data) return response.data;

    return { error: error.message || error };
  }
};

export const verifyPasswordResetToken = async (token, userId) => {
  try {
    const { data } = await client.post("/users/verify-pass-reset-token", {
      token,
      userId,
    });
    return data;
  } catch (error) {
    const { response } = error;
    if (response?.data) return response.data;

    return { error: error.message || error };
  }
};

export const resetPassword = async (passwordInfo) => {
  try {
    const { data } = await client.post("/users/reset-password", passwordInfo);
    return data;
  } catch (error) {
    const { response } = error;
    if (response?.data) return response.data;

    return { error: error.message || error };
  }
};
export const changePassword = async (passwordInfo) => {
  const config = {
    headers: {
      authorization: "Bearer " + token,
    },
  };
  try {
    const { data } = await client.patch(
      "/users/changePassword",
      passwordInfo,
      config
    );
    return data;
  } catch (error) {
    const { response } = error;
    if (response?.data) return response.data;
    return { error: error.message || error };
  }
};

export const resendEmailVerificationToken = async (userId) => {
  try {
    const { data } = await client.post(
      "/users/resend-email-verification-token",
      {
        userId,
      }
    );
    return data;
  } catch (error) {
    const { response } = error;
    if (response?.data) return response.data;

    return { error: error.message || error };
  }
};

// Update user verified status

export const UpdateUserVerifiedStatus = async (payload) => {
  const token = getToken();
  const config = {
    headers: {
      authorization: "Bearer " + token,
    },
  };
  try {
    const { data } = await client.post(
      "/users/update-user-verified-status",
      payload,
      config
    );
    return data;
  } catch (error) {
    return error.response.data;
  }
};
export const UpdateIsVerifiedStatus = async (selectedUser, isVerified) => {
  const token = getToken();
  const config = {
    headers: {
      authorization: "Bearer " + token,
    },
  };
  try {
    const { data } = await client.post(
      "/branch/update-is-verified-status",
      selectedUser,
      isVerified,
      config
    );
    return data;
  } catch (error) {
    return error.response.data;
  }
};

export const deleteBranchUser = async (userId) => {
  const token = getToken();
  const config = {
    headers: {
      authorization: "Bearer " + token,
    },
  };
  const { data } = await client.delete(`/branch/${userId}`, config);
  return data;
};
export const getBranchUserByAdmin = async (userId) => {
  const token = getToken();
  const config = {
    headers: {
      authorization: "Bearer " + token,
    },
  };
  const { data } = await client.get(`/branch/getUserByAdmin/${userId}`, config);
  return data;
};

export const updateBranchUsers = async (userId, userInfo) => {
  try {
    const token = getToken();
    const { data } = await client.put(`/branch/${userId}`, userInfo, {
      headers: {
        authorization: "Bearer " + token,
      },
    });

    return data;
  } catch (error) {
    return catchError(error);
  }
};

export const getNotification = async (id) => {
  try {
    const token = getToken();
    const config = {
      headers: {
        authorization: "Bearer " + token,
      },
    };
    const { data } = await client.get(`/notification/${id}`, config);
    return data;
  } catch (error) {
    return catchError(error);
  }
};
export const fetchAllBranchUsers = async () => {
  const token = getToken();
  const config = {
    headers: {
      authorization: "Bearer " + token,
    },
  };
  try {
    const { data } = await client.get("/users/branch", config);
    // console.log(data);
    return data;
  } catch (error) {
    return catchError(error);
  }
};

export const getAccountDetails = async () => {
  try {
    const { data } = await client.get("/users/accountDeatils", {
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    return data;
  } catch (error) {
    const { response } = error;
    if (response?.data) return response.data;

    return { error: error.message || error };
  }
};
export const checkPinStatus = async (userId) => {
  try {
    const { data } = await client.get(`/users/check-pin/${userId}`, {
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    return data;
  } catch (error) {
    const { response } = error;
    if (response?.data) return response.data;

    return { error: error.message || error };
  }
};
export const verifyPin = async (userId, pin) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  try {
    const { data } = await client.post(
      `/users/verify-pin`,
      { userId, pin },
      config
    );
    return data;
  } catch (error) {
    const { response } = error;
    if (response?.data) return response.data;

    return { error: error.message || error };
  }
};
export const resetPin = async (userId, newPin) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  try {
    const { data } = await client.post(
      "/users/reset-pin",
      { userId, newPin },
      config
    );
    return data;
  } catch (error) {
    const { response } = error;
    if (response?.data) return response.data;

    return { error: error.message || error };
  }
};
