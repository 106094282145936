import React, { useState, Suspense, lazy, useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import LoadingPage from "../pages/LoadingPage";
import MarketerNavbar from "../roleNav/MarketerNavbar";
import MarketerHeader from "../roleHeaders/MarketerHeader";
import CustomerList from "../pages/marketer/CustomerList";
import RecordDeposit from "../pages/marketer/RecordDeposit";
import DepositHistory from "../pages/marketer/DepositHistory";
import Loading from "../pages/Loading";

const MarketerDashboard = lazy(() =>
  import("../roleDashboards/MarketerDashboard")
);
const Messenger = lazy(() => import("../chat"));

const MarketerNavigator = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [collapsed, setCollapsed] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 3000);

    return () => clearTimeout(timer);
  }, []);

  if (isLoading) {
    return <LoadingPage />;
  }

  return (
    <div className="flex dark:bg-primary bg-white w-full">
      <MarketerNavbar onCollapseChange={setCollapsed} />
      <div className="flex-1 w-full max-w-screen-xxl">
        <MarketerHeader
        // onCreateBranchUserClick={displayCreateBranchUserModal}
        />
        <Routes>
          <Route
            path="/"
            element={
              <Suspense
                fallback={
                  <div>
                    <LoadingPage />
                  </div>
                }
              >
                <MarketerDashboard isCollapsed={collapsed} />
              </Suspense>
            }
          />
          <Route path="/marketer/customers" element={<CustomerList />} />
          <Route path="/marketer/deposit" element={<RecordDeposit />} />
          <Route path="/marketer/deposits" element={<DepositHistory />} />
          <Route
            path="/marketer/messageStaff"
            element={
              <Suspense
                fallback={
                  <div>
                    <Loading />
                  </div>
                }
              >
                <Messenger />
              </Suspense>
            }
          />
        </Routes>
      </div>
    </div>
  );
};

export default MarketerNavigator;
