import React, { useEffect, useState, useCallback } from "react";
import { transactionAccount } from "../../../api/transactions";

const AccountsTable = ({ formatCurrency, currencyCode, currencyIcon }) => {
  const [accounts, setAccounts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");

  const fetchAccounts = useCallback(async (page) => {
    try {
      const res = await transactionAccount(page, 6);
      setAccounts(res.accounts);
      setTotalPages(res.totalPages);
    } catch (error) {
      console.error("Error fetching accounts:", error);
    }
  }, []);

  useEffect(() => {
    fetchAccounts(currentPage);
  }, [currentPage]);

  const handlePageChange = (newPage) => {
    if (newPage > 0 && newPage <= totalPages) {
      setCurrentPage(newPage);
    }
  };

  const handleSearch = (e) => {
    setSearchQuery(e.target.value.toLowerCase());
  };

  const filteredAccounts = accounts?.filter((account) =>
    `${account?.userId?.first_name} ${account?.userId?.last_name}`
      .toLowerCase()
      .includes(searchQuery)
  );

  return (
    <div className="accounts-table">
      <div className="overflow-x-auto">
        <p className="font-medium">Transaction Account</p>
        <input
          type="text"
          placeholder="Search accounts..."
          className="my-4 p-2 border border-gray-300 rounded"
          value={searchQuery}
          onChange={handleSearch}
        />

        <table className="w-full whitespace-nowrap">
          <thead>
            <tr className="bg-[rgba(67, 113, 233, .05)]1/5 dark:bg-blue-300">
              <th className="text-start py-5 px-6 cursor-pointer min-w-[300px]">
                <TableHeader title="Title" />
              </th>
              <th className="text-start py-5 w-[14%] cursor-pointer">
                <TableHeader title="Amount" />
              </th>
            </tr>
          </thead>
          <tbody>
            {filteredAccounts?.map((account, index) => (
              <AccountRow
                key={index}
                account={account}
                formatCurrency={formatCurrency}
                currencyCode={currencyCode}
                currencyIcon={currencyIcon}
              />
            ))}
          </tbody>
        </table>
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={handlePageChange}
        />
      </div>
    </div>
  );
};

const TableHeader = ({ title }) => (
  <div className="flex items-center gap-1">
    {title}
    <SvgIcon />
  </div>
);

const SvgIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={18}
    height={18}
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth={2}
    strokeLinecap="round"
    strokeLinejoin="round"
    className="tabler-icon tabler-icon-selector"
  >
    <path d="M8 9l4 -4l4 4" />
    <path d="M16 15l-4 4l-4 -4" />
  </svg>
);

const AccountRow = ({
  account,
  formatCurrency,
  currencyCode,
  currencyIcon,
}) => {
  const { userId, accountNumber, balance } = account;
  const { first_name, last_name, picture } = userId || {};

  return (
    <tr className="bg-[rgba(67, 113, 233, .05)]1/5 dark:bg-blue-300">
      <td className="py-2 px-6">
        <div className="flex items-center gap-3">
          <img
            alt={first_name}
            loading="lazy"
            width={60}
            height={40}
            decoding="async"
            data-nimg={1}
            className="rounded"
            src={picture}
            style={{ color: "transparent" }}
          />
          <div>
            <p className="font-medium mb-1">{`${first_name} ${last_name}`}</p>
            <span className="text-xs">{accountNumber}</span>
          </div>
        </div>
      </td>
      <td className="py-2">
        <div>
          <p className="font-medium">
            {formatCurrency(
              balance === 0 ? 0 : balance,
              currencyIcon,
              currencyCode
            )}
          </p>
          <span className="text-xs">Account Balance</span>
        </div>
      </td>
    </tr>
  );
};

const Pagination = ({ currentPage, totalPages, onPageChange }) => {
  const pagesToShow = 5;
  const startPage = Math.max(currentPage - Math.floor(pagesToShow / 2), 1);
  const endPage = Math.min(startPage + pagesToShow - 1, totalPages);

  const pages = [];
  for (let i = startPage; i <= endPage; i++) {
    pages.push(i);
  }

  return (
    <div className="flex justify-center my-4">
      <button
        onClick={() => onPageChange(currentPage - 1)}
        disabled={currentPage === 1}
        className="px-3 py-1 mx-1 bg-gray-300 rounded"
      >
        &lt;
      </button>
      {pages?.map((page) => (
        <button
          key={page}
          onClick={() => onPageChange(page)}
          className={`px-3 py-1 mx-1 ${
            page === currentPage ? "bg-blue-500 text-white" : "bg-gray-300"
          } rounded`}
        >
          {page}
        </button>
      ))}
      <button
        onClick={() => onPageChange(currentPage + 1)}
        disabled={currentPage === totalPages}
        className="px-3 py-1 mx-1 bg-gray-300 rounded"
      >
        &gt;
      </button>
    </div>
  );
};

export default AccountsTable;
