import React, { useState } from "react";
import useConversation from "../chat/zustand/useConversation";
import { sendMessageToUser } from "../../api/messenger/messenger";
import { toast } from "react-toastify";

const useSendMessage = () => {
  const [loading, setLoading] = useState(false);
  const { messages, setMessages, selectedConversation } = useConversation();

  const sendMessage = async (message) => {
    try {
      setLoading(true);
      const res = await sendMessageToUser(selectedConversation?._id, {
        message,
      });
      if (res.error) throw new Error(res.error);
      setMessages([...messages, res]);
    } catch (error) {
      toast.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  return { sendMessage, loading };
};

export default useSendMessage;
