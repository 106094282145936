import React from "react";
import { Link } from "react-router-dom";

export default function CustomLink({ to, children }) {
  return (
    <Link
      className="dark:!text-dark-subtle !text-light-subtle dark:hover:!text-white hover:!text-blue-700 hover:!underline hover:!underline-offset-4 transition"
      to={to}
    >
      {children}
    </Link>
  );
}
