import React from "react";
import { Link } from "react-router-dom";
import Home1 from "./Home1";

const Card = () => {
  return (
    <>
      <section className="pb-20 bg-blueGray-200 -mt-24">
        <div className="container mx-auto px-4">
          <div className="flex flex-wrap">
            <div className="lg:pt-12 pt-6 w-full md:w-4/12 px-4 text-center">
              <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg">
                <div className="px-4 py-5 flex-auto">
                  <div className="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-red-400">
                    <i className="fas fa-award"></i>
                  </div>
                  <h6 className="text-xl font-semibold">Trusted Services</h6>
                  <p className="mt-2 mb-4 text-blueGray-500">
                    Our services include Teaching different languages which
                    range from English to your preferred language...
                    <Link to="/readMore...">read more..</Link>
                  </p>
                </div>
              </div>
            </div>

            <div className="w-full md:w-4/12 px-4 text-center">
              <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg">
                <div className="px-4 py-5 flex-auto">
                  <div className="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-lightBlue-400">
                    <i className="fas fa-retweet"></i>
                  </div>
                  <h6 className="text-xl font-semibold">Free Revisions</h6>
                  <p className="mt-2 mb-4 text-blueGray-500">
                    Your satisfaction is paramount. If you need tweaks or
                    changes to your order, we’re on it—<strong>no cost</strong>,{" "}
                    <strong>no fuss</strong>. Just let us know, and we’ll make
                    sure everything is <strong>just right</strong>.
                  </p>
                </div>
              </div>
            </div>

            <div className="pt-6 w-full md:w-4/12 px-4 text-center">
              <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg">
                <div className="px-4 py-5 flex-auto">
                  <div className="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-emerald-400">
                    <i className="fas fa-fingerprint"></i>
                  </div>
                  <h6 className="text-xl font-semibold">Verified Company</h6>
                  <p className="mt-2 mb-4 text-blueGray-500">
                    Navigate the complexities of financial management with
                    confidence. Our <strong>Banking & Finance</strong>{" "}
                    feature... <Link to="/readMore...">read more..</Link>
                  </p>
                </div>
              </div>
            </div>
          </div>

          <Home1 />
        </div>
      </section>
    </>
  );
};

export default Card;
