import { getToken } from "../utils/helper";
import client from "./client";
const token = getToken();

// verify receiver account

export const VerifyAccount = async (payload) => {
  try {
    const { data } = await client.post(
      "/transactions/verify-account",
      payload,
      {
        headers: {
          authorization: "Bearer " + token,
        },
      }
    );
    return data;
  } catch (error) {
    return error.response.data;
  }
};

// transfer funds
export const TransferFunds = async (payload) => {
  const token = getToken();
  try {
    const { data } = await client.post(
      "/transactions/transfer-funds",
      payload,
      {
        headers: {
          authorization: "Bearer " + token,
        },
      }
    );
    return data;
  } catch (error) {
    return error.response.data;
  }
};

// Bank Transfer
export const BankTrsf = async (payload) => {
  const token = getToken();
  try {
    const { data } = await client.post("/transactions/transfer", payload, {
      headers: {
        authorization: "Bearer " + token,
      },
    });
    return data;
  } catch (error) {
    return error.response.data;
  }
};
// Bank Transfer
export const getBanks = async () => {
  const token = getToken();
  try {
    const { data } = await client.get("/transactions/banks", {
      headers: {
        authorization: "Bearer " + token,
      },
    });
    return data;
  } catch (error) {
    return error.response.data;
  }
};

// get all transactions for a user
export const GetTransactionsOfUser = async (userId) => {
  const token = getToken();
  try {
    const { data } = await client.get(
      `/transactions/get-all-transactions-by-user/${userId}`,
      {
        headers: {
          authorization: "Bearer " + token,
          accept: "application/json",
        },
      }
    );
    return data;
  } catch (error) {
    return error.response.data;
  }
};
// get all transactions for a user
export const GetTransactionsByAdmin = async () => {
  const token = getToken();
  try {
    const { data } = await client.get("/transactions/get-all-transactions", {
      headers: {
        authorization: "Bearer " + token,
        accept: "application/json",
      },
    });
    return data;
  } catch (error) {
    return error.response.data;
  }
};
// get all transactions for a user
export const GetFiveLatestTransactions = async (userId) => {
  const token = getToken();
  try {
    const { data } = await client.get(
      `/transactions/get-five-latest-transactions/${userId}`,
      {
        headers: {
          authorization: "Bearer " + token,
          accept: "application/json",
        },
      }
    );
    return data;
  } catch (error) {
    return error.response.data;
  }
};
export const GetFiveRecentTransactions = async () => {
  const token = getToken();
  try {
    const { data } = await client.get(
      `/transactions/get-five-recent-transaction`,
      {
        headers: {
          authorization: "Bearer " + token,
          accept: "application/json",
        },
      }
    );
    return data;
  } catch (error) {
    return error.response.data;
  }
};

// deposit funds using stripe

export const DepositFunds = async (payload) => {
  const token = getToken();
  try {
    const { data } = await client.post("/account/generate-reference", payload, {
      headers: {
        authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
    });
    return data;
  } catch (error) {
    return error.response.data;
  }
};
export const VerifyFunds = async (payload) => {
  const token = getToken();
  try {
    const { data } = await client.post("/users/verify-payment", payload, {
      headers: {
        authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
    });
    return data;
  } catch (error) {
    return error.response.data;
  }
};
export const Deposit = async (formData) => {
  const token = getToken();
  try {
    const { data } = await client.post("/account/deposit", formData, {
      headers: {
        authorization: "Bearer " + token,
        "content-type": "multipart/form-data",
      },
    });
    return data;
  } catch (error) {
    return error.response.data;
  }
};

// deposit funds using stripe
export const DepositFundsStripe = async (payload) => {
  const token = getToken();
  try {
    const { data } = await client.post("/transactions/deposit-funds", payload, {
      headers: {
        authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
    });
    return data;
  } catch (error) {
    return error.response.data;
  }
};
export const getDepositTransactions = async () => {
  const token = getToken();
  try {
    const { data } = await client.get("/transactions/deposit-transaction", {
      headers: {
        authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
    });
    return data;
  } catch (error) {
    return error.response.data;
  }
};
export const updateTransactionStatus = async (transactionId) => {
  try {
    const { data } = await client.put(
      `/account/update-transaction/${transactionId}`,
      {},
      {
        headers: {
          authorization: "Bearer " + token,
          "Content-Type": "application/json",
        },
      }
    );
    return data;
  } catch (error) {
    return error.response.data;
  }
};
export const transactionStats = async () => {
  try {
    const { data } = await client.get(`/transactions/stats`, {
      headers: {
        authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
    });
    return data;
  } catch (error) {
    return error.response.data;
  }
};
export const transactionAccount = async (page = 1, limit = 6) => {
  try {
    const { data } = await client.get(`/transactions/transaction-accounts`, {
      params: { page, limit },
      headers: {
        authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
    });
    return data;
  } catch (error) {
    return error.response.data;
  }
};
export const allTransactions = async (page = 1, limit = 10) => {
  try {
    const { data } = await client.get(`/transactions/all-transactions`, {
      params: { page, limit },
      headers: {
        authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
    });
    return data;
  } catch (error) {
    return error.response.data;
  }
};
