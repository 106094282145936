import React from "react";

const Home1 = () => {
  return (
    <>
      <div className="flex flex-wrap items-center mt-32">
        <div className="w-full md:w-5/12 px-4 mr-auto ml-auto">
          <div className="text-blueGray-500 p-3 text-center inline-flex items-center justify-center w-16 h-16 mb-6 shadow-lg rounded-full bg-white">
            <i className="fas fa-user-friends text-xl"></i>
          </div>
          <h3 className="text-3xl mb-2 font-semibold leading-normal">
            Working with us is a pleasure
          </h3>
          <p className="text-lg font-light leading-relaxed mt-4 mb-4 text-blueGray-600">
            Partnering with royalKatd means engaging with a team that
            prioritizes clarity, efficiency, and innovation. Our intuitive tools
            and platforms are designed to eliminate guesswork, providing you
            with seamless interactions and clear guidance. We understand the
            importance of your time and strive to enhance your experience with
            quick, actionable insights and support that’s just a click away.
          </p>
          <p className="text-lg font-light leading-relaxed mt-0 mb-4 text-blueGray-600">
            At royalKatd, we’re not just a service; we’re your trusted ally in
            achieving your goals. Our commitment to excellence is evident in
            every interaction, with customized solutions tailored to your unique
            needs. We provide you with the resources to get started swiftly, and
            our dedicated team ensures that you have all the support you need to
            succeed. Discover the difference that comes with a partnership that
            values your success as much as you do.
          </p>
        </div>

        <div className="w-full md:w-4/12 px-4 mr-auto ml-auto">
          <div className="relative flex flex-col min-w-0 break-words bg-gray-400 w-full mb-6 shadow-lg rounded-lg bg-lightBlue-500">
            <img
              alt="..."
              src="https://images.unsplash.com/photo-1522202176988-66273c2fd55f?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1051&q=80"
              className="w-full align-middle rounded-t-lg"
            />
            <blockquote className="relative p-8 mb-4">
              <svg
                preserveAspectRatio="none"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 583 95"
                className="absolute left-0 w-full block h-95-px -top-94-px"
              >
                <polygon
                  points="-30,95 583,95 583,65"
                  className="text-lightBlue-500 fill-current"
                ></polygon>
              </svg>
              <h4 className="text-xl font-bold text-white">
                Top Notch Services
              </h4>
              <p className="text-md font-light mt-2 text-white">
                Experience unparalleled excellence with our services. We ensure
                a consistent, quality experience—season after season, year after
                year.
              </p>
            </blockquote>
          </div>
        </div>
      </div>
    </>
  );
};

export default Home1;
