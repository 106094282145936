import React, { useEffect, Suspense, lazy, useState } from "react";
import { ToastContainer } from "react-toastify";
import { Route, Routes } from "react-router-dom";
import { useAuth } from "./component/hooks";
import "react-toastify/dist/ReactToastify.css";
import { Helmet, HelmetProvider } from "react-helmet-async";
import "./App.css";
import "./assets/styles/tailwind.css";
import { Bank } from "./component/departments/Bank";
import {
  fetchGeneralSettings,
  fetchLogos,
  fetchSettings,
} from "./utils/helper";
import Footer from "./component/pages/other/Footer";
import AdSense from "./Adsence";
import NetworkStatus from "./NetworkStatus";
import NavigationBar from "./views/home/Navbar";
import HomePage from "./views/home/HomePage";
import ConfirmPassword from "./component/auth/ConfirmPassword";
import ForgetPassword from "./component/auth/ForgetPassword";
import ScrollToTop from "./utils/ScrollToTop";
import MarketerNavigator from "./component/rolePages/MarketerNavigator";
import CashierNavigator from "./component/rolePages/CashierNavigator";
import Loading from "./component/pages/Loading";
import SEOComponent from "./component/SEOComponent";
import { getLogoAndFavicon } from "./api/logoSetting";
const ViewMorePage = lazy(() => import("./component/pages/ViewMorePage"));
const ReadMore = lazy(() => import("./component/pages/ReadMore"));
const LoginBranch = lazy(() => import("./component/pages/LoginBranch"));
const AboutPage = lazy(() => import("./component/pages/AboutPage"));
const PaymentSuccess = lazy(() => import("./component/pages/PaymentSuccess"));
const LoadingPage = lazy(() => import("./component/pages/LoadingPage"));
const ContactUs = lazy(() => import("./component/pages/ContactUs"));
const Services = lazy(() => import("./component/pages/Services"));
const AdminNavigator = lazy(() =>
  import("./component/rolePages/AdminNavigator")
);
const BranchNavigator = lazy(() =>
  import("./component/rolePages/BranchNavigator")
);
const SubscriberNavigator = lazy(() =>
  import("./component/rolePages/SubscriberNavigator")
);
const EmailVerification = lazy(() =>
  import("./component/auth/EmailVerification")
);
const Signup = lazy(() => import("./views/auth/RegisterComponent"));
const Signin = lazy(() => import("./views/auth/LoginComponent"));
const NotFound = lazy(() => import("./component/NotFound"));
const Team = lazy(() => import("./component/pages/other/Team"));
const Privacy = lazy(() => import("./component/pages/other/Privacy"));
const Terms = lazy(() => import("./component/pages/other/Terms"));

function App() {
  const [generalSettings, setGeneralSettings] = useState([]);
  const [settings, setSettings] = useState([]);
  const [startAnimation, setStartAnimation] = useState(false);
  const [logo, setLogo] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 1000);

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    const fetchGeneralSettingsData = async () => {
      const res = await fetchGeneralSettings();
      const siteName = res?.settings?.[0]?.site_name;
      setGeneralSettings(siteName || "royalKatd");
    };

    fetchGeneralSettingsData();
  }, []);
  useEffect(() => {
    const fetchSettingsData = async () => {
      const res = await fetchSettings();

      setSettings(res?.payment);
    };

    fetchSettingsData();
  }, []);

  useEffect(() => {
    const fetchSettings = async () => {
      try {
        const response = await getLogoAndFavicon();
        setLogo(response?.logo?.url);
      } catch (error) {
        console.error("Error fetching settings", error);
      }
    };

    fetchSettings();
  }, []);

  const helmetContext = {};
  useEffect(() => {
    window.process = {
      ...window.process,
    };
  }, []);

  useEffect(() => {
    const handleScroll = (_event) => {
      if (window.scrollY > window.innerHeight && !startAnimation) {
        setStartAnimation(true);
      }

      if (window.scrollY < window.innerHeight && startAnimation) {
        setStartAnimation(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [startAnimation]);

  const { authInfo } = useAuth();
  const isSuperAdmin = authInfo.profile?.role === "superAdmin";
  // branch
  const isBranchManager = authInfo.profile?.role === "manager";
  const isSubscriber = authInfo.profile?.role === "user";
  const isMarketer = authInfo.profile?.role === "marketer";
  const isCashier = authInfo.profile?.role === "cashier";

  if (isSuperAdmin) {
    return <AdminNavigator favicon={logo} />;
  } else if (isBranchManager) {
    return <BranchNavigator />;
  } else if (isSubscriber) {
    return <SubscriberNavigator favicon={logo} />;
  } else if (isMarketer) {
    return <MarketerNavigator favicon={logo} />;
  } else if (isCashier) {
    return <CashierNavigator favicon={logo} />;
  }

  if (isLoading) {
    return <LoadingPage />;
  }

  return (
    <>
      <HelmetProvider context={helmetContext}>
        <SEOComponent />
        <AdSense />
        <NetworkStatus />

        <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
          <NavigationBar logo={logo} />
          <main>
            <div className="dark:!bg-[#182235] px-2 sm:px-3 lg:px-4 py-4 w-full h-screen max-w-9xl mx-auto overflow-auto scrollbar dashoard">
              <Routes>
                <Route
                  path="/"
                  element={
                    <Suspense
                      fallback={
                        <div>
                          <LoadingPage />
                        </div>
                      }
                    >
                      <HomePage />
                    </Suspense>
                  }
                />
                <Route path="/payment-success" element={<PaymentSuccess />} />
                <Route
                  path="/auth/signin"
                  element={
                    <Suspense
                      fallback={
                        <div>
                          <Loading />
                        </div>
                      }
                    >
                      <Signin />
                    </Suspense>
                  }
                />
                <Route
                  path="/auth/sign-in-v2-manager"
                  element={
                    <Suspense
                      fallback={
                        <div>
                          <Loading />
                        </div>
                      }
                    >
                      <LoginBranch />
                    </Suspense>
                  }
                />
                <Route
                  path="/auth/signup"
                  element={
                    <Suspense
                      fallback={
                        <div>
                          <Loading />
                        </div>
                      }
                    >
                      <Signup />
                    </Suspense>
                  }
                />
                <Route
                  path="/auth/verification"
                  element={
                    <Suspense
                      fallback={
                        <div>
                          <Loading />
                        </div>
                      }
                    >
                      <EmailVerification />
                    </Suspense>
                  }
                />

                <Route
                  path="/auth/forget-password"
                  element={
                    <Suspense
                      fallback={
                        <div>
                          <Loading />
                        </div>
                      }
                    >
                      <ForgetPassword />
                    </Suspense>
                  }
                />
                <Route
                  path="/auth/reset-password"
                  element={
                    <Suspense
                      fallback={
                        <div>
                          <Loading />
                        </div>
                      }
                    >
                      <ConfirmPassword />
                    </Suspense>
                  }
                />

                {/* ORDERS */}
                <Route
                  path="/about"
                  element={
                    <Suspense
                      fallback={
                        <div>
                          <Loading />
                        </div>
                      }
                    >
                      <AboutPage />
                    </Suspense>
                  }
                />
                <Route
                  path="/readMore..."
                  element={
                    <Suspense
                      fallback={
                        <div>
                          <Loading />
                        </div>
                      }
                    >
                      <ViewMorePage />
                    </Suspense>
                  }
                />
                <Route
                  path="/our-services"
                  element={
                    <Suspense
                      fallback={
                        <div>
                          <Loading />
                        </div>
                      }
                    >
                      <ReadMore />
                    </Suspense>
                  }
                />
                <Route
                  path="/contact"
                  element={
                    <Suspense
                      fallback={
                        <div>
                          <Loading />
                        </div>
                      }
                    >
                      <ContactUs />
                    </Suspense>
                  }
                />
                <Route
                  path="/service"
                  element={
                    <Suspense
                      fallback={
                        <div>
                          <Loading />
                        </div>
                      }
                    >
                      <Services />
                    </Suspense>
                  }
                />
                <Route
                  path="/departments"
                  element={
                    <Suspense
                      fallback={
                        <div>
                          <Loading />
                        </div>
                      }
                    >
                      <Bank />
                    </Suspense>
                  }
                />

                <Route
                  path="/team"
                  element={
                    <Suspense
                      fallback={
                        <div>
                          <Loading />
                        </div>
                      }
                    >
                      <Team />
                    </Suspense>
                  }
                />
                <Route
                  path="/privacy"
                  element={
                    <Suspense
                      fallback={
                        <div>
                          <Loading />
                        </div>
                      }
                    >
                      <Privacy />
                    </Suspense>
                  }
                />
                <Route
                  path="/privacyPolicy"
                  element={
                    <Suspense
                      fallback={
                        <div>
                          <Loading />
                        </div>
                      }
                    >
                      <Terms />
                    </Suspense>
                  }
                />
                <Route
                  path="*"
                  element={
                    <Suspense
                      fallback={
                        <div>
                          <Loading />
                        </div>
                      }
                    >
                      <NotFound />
                    </Suspense>
                  }
                />
              </Routes>
            </div>
          </main>
        </div>
        <Footer />
        <ScrollToTop startAnimation={startAnimation} />
      </HelmetProvider>
      <ToastContainer />
    </>
  );
}

export default App;
