import React, { useEffect, useState } from "react";
import {
  getSpendingGoals,
  handleUpdateGoal,
} from "../../../api/cashier/cashier";
import axios from "axios";
import { formatCurrency, getCurrencyIcon } from "../../../utils/helper";
import GoalContributionForm from "../../form/GoalContributionForm";
import ModalContainer from "../../models/ModalContainer";

const SpendingGoalList = ({ userId, spendingGoals, setSpendingGoals }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [currencyIcon, setCurrencyIcon] = useState("");
  const [currencyCode, setCurrencyCode] = useState("");
  const [showContributeModal, setShowContributeModal] = useState(false);
  const [selectedGoalId, setSelectedGoalId] = useState(null);

  const hideContributeModal = () => {
    setShowContributeModal(false);
  };

  useEffect(() => {
    const fetchCurrencyIcon = async () => {
      try {
        const data = await getCurrencyIcon();
        setCurrencyIcon(data?.currencyIcon);
        setCurrencyCode(data?.currencyCode);
      } catch (error) {
        console.error("Error fetching currency icon:", error);
      }
    };

    fetchCurrencyIcon();
  }, []);

  const handleMarkAsCompleted = async (id) => {
    try {
      const response = await handleUpdateGoal(id, { status: "completed" });
      setSpendingGoals((prevGoals) =>
        prevGoals?.map((goal) => (goal?._id === id ? response : goal))
      );
    } catch (error) {
      console.error("Error marking goal as completed:", error);
    }
  };

  const filteredGoals = spendingGoals.filter((goal) => {
    const goalAmount = goal?.goalAmount?.toString().toLowerCase() || "";
    const currentAmount = goal?.currentAmount?.toString().toLowerCase() || "";
    const dueDate =
      new Date(goal?.dueDate)?.toLocaleDateString().toLowerCase() || "";
    const status = goal?.status?.toLowerCase() || "";

    const term = searchTerm.toLowerCase();

    return (
      goalAmount.includes(term) ||
      currentAmount.includes(term) ||
      dueDate.includes(term) ||
      status.includes(term)
    );
  });

  const handleContributionSuccess = async () => {
    try {
      const updatedGoals = await getSpendingGoals(userId);
      setSpendingGoals(updatedGoals);
      setShowContributeModal(false);
    } catch (error) {
      console.error("Error updating spending goals after contribution:", error);
    }
  };

  return (
    <div className="p-4 overflow-auto scrollbar max-h-96">
      <input
        type="text"
        placeholder="Search..."
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        className="mb-4 p-2 border border-gray-300 rounded"
      />
      <table className="min-w-full bg-white border border-gray-200">
        <thead>
          <tr className="bg-gray-100 border-b">
            <th className="p-2 border-r">Goal Amount</th>
            <th className="p-2 border-r">Note</th>
            <th className="p-2 border-r">Current Amount</th>
            <th className="p-2 border-r">Due Date</th>
            <th className="p-2 border-r">Status</th>
            <th className="p-2">Actions</th>
          </tr>
        </thead>
        <tbody>
          {filteredGoals?.map((goal, index) => (
            <tr key={index}>
              <td className="p-2 border-b border-r">
                {formatCurrency(
                  goal?.goalAmount === 0 ? 0 : goal?.goalAmount,
                  currencyIcon,
                  currencyCode
                )}
              </td>
              <td className="p-2 border-b border-r">{goal?.note}</td>

              <td className="p-2 border-b border-r">
                {formatCurrency(
                  goal?.currentAmount === 0 ? 0 : goal?.currentAmount,
                  currencyIcon,
                  currencyCode
                )}
              </td>
              <td className="p-2 border-b border-r">
                {new Date(goal?.dueDate)?.toLocaleDateString()}
              </td>
              <td className="p-2 border-b border-r">{goal?.status}</td>
              <td className="p-2 border-b flex justify-between">
                {goal?.status === "completed" ? (
                  <button
                    className={
                      goal?.status === "completed"
                        ? "cursor-not-allowed bg-gray-500 hover:bg-gray-700 text-white text-xs font-bold py-1 px-2 rounded"
                        : "bg-gray-500 hover:bg-gray-700 text-white text-xs font-bold py-1 px-2 rounded"
                    }
                    disabled={goal?.status === "completed"}
                  >
                    Completed
                  </button>
                ) : (
                  <button
                    onClick={() => handleMarkAsCompleted(goal?._id)}
                    className="bg-green-500 hover:bg-green-700 text-white text-xs font-bold py-1 px-2 rounded"
                  >
                    Mark as Completed
                  </button>
                )}
                {goal?.status === "completed" ? (
                  ""
                ) : (
                  <button
                    onClick={() => {
                      setSelectedGoalId(goal?._id);
                      setShowContributeModal(true);
                    }}
                    className=" bg-purple-500 hover:bg-purple-700 text-white text-xs font-bold py-1 px-2 rounded"
                    disabled={goal?.status === "completed"}
                  >
                    Contribute
                  </button>
                )}

                {showContributeModal && (
                  <ModalContainer
                    visible={showContributeModal}
                    onClose={hideContributeModal}
                    ignoreContainer
                  >
                    <GoalContributionForm
                      goalId={selectedGoalId}
                      onContributionSuccess={handleContributionSuccess}
                    />
                  </ModalContainer>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default SpendingGoalList;
