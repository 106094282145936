import React from "react";
import useConversation from "../zustand/useConversation";
import { useSocketContext } from "../../context/SocketContext";
import useGetMessages from "../../hooks/useGetMessages";
import { updateMessageStatus } from "../../../api/messenger/messenger";

const Conversation = ({ conversation, lastIdx, emoji }) => {
  // console.log("MESSAGEs:: ", conversation);
  const { selectedConversation, setSelectedConversation } = useConversation();
  const { onlineUsers } = useSocketContext();
  const { messages } = useGetMessages();

  const isSelected = selectedConversation?._id === conversation?._id;
  const isOnline = onlineUsers?.includes(conversation?._id);

  // Calculate the number of unread messages
  const unreadMessages = messages?.filter(
    (message) =>
      message?.status === "unseen" && message?.receiverId === conversation?._id
  )?.length;

  // console.log("Messages:", messages);
  // console.log("Conversation ID:", conversation?._id);
  // console.log("Unread Messages:", unreadMessages);

  const handleClick = async () => {
    setSelectedConversation(conversation);

    // Update unread messages to "seen"
    messages?.forEach(async (message) => {
      if (
        message?.status === "unseen" &&
        message?.receiverId === conversation?._id
      ) {
        await updateMessageStatus(message?._id, "seen");
      }
    });
  };

  return (
    <>
      <div
        className={`flex gap-2 items-center hover:bg-sky-500 rounded p-2 py-1 cursor-pointer ${
          isSelected ? "bg-sky-500" : ""
        }`}
        onClick={handleClick}
      >
        <div className={`avatar ${isOnline ? "online" : ""}`}>
          <div className="w-12 rounded-full">
            <img src={conversation?.picture} alt="user avatar" />
          </div>
        </div>
        <div className="flex flex-col flex-1">
          <div className="flex gap-3 justify-between">
            <div className="font-bold text-gray-200">
              <span className="text-xs text-gray-500 m-0 p-0">
                {conversation?.role}
              </span>
              <p>{conversation?.first_name + " " + conversation?.last_name}</p>
            </div>
            {!isSelected && unreadMessages > 0 ? (
              <div className="bg-red-500 text-white rounded-full h-6 w-6 flex items-center justify-center">
                {unreadMessages}
              </div>
            ) : (
              <span className="text-xl">{emoji}</span>
            )}
          </div>
        </div>
      </div>
      {!lastIdx && <div className="divider my-0 py-0 h-1" />}
    </>
  );
};

export default Conversation;
