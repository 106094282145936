import axios from "axios";
import { catchError, getToken } from "../../utils/helper";
import client from "../client";

const token = getToken();

export const getUserConversations = async () => {
  try {
    const { data } = await client.get("/messages/chat-users", {
      headers: {
        authorization: "Bearer " + token,
      },
    });
    return data;
  } catch (error) {
    return catchError(error);
  }
};
export const sendMessageToUser = async (id, message) => {
  try {
    const { data } = await client.post(`/messages/send/${id}`, message, {
      headers: {
        authorization: "Bearer " + token,
        accept: "application/json",
      },
    });
    return data;
  } catch (error) {
    return catchError(error);
  }
};
export const getMessage = async (id) => {
  try {
    const { data } = await client.get(`/messages/${id}`, {
      headers: {
        authorization: "Bearer " + token,
        accept: "application/json",
      },
    });
    return data;
  } catch (error) {
    return catchError(error);
  }
};

export const updateMessageStatus = async (messageId, status) => {
  try {
    const { data } = await client.patch(
      `/messages/${messageId}`,
      {
        status,
      },
      {
        headers: {
          authorization: "Bearer " + token,
          accept: "application/json",
        },
      }
    );
    return data;
  } catch (error) {
    console.error("Error updating message status:", error);
    return catchError(error);
  }
};
