import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { FaFacebook, FaInstagram, FaTwitter } from "react-icons/fa";
import { TiSocialPinterest } from "react-icons/ti";
import { getFooter } from "../../../api/footer/footer";

const Footer = () => {
  const [footerData, setFooterData] = useState({
    titleGrid: "",
    titleGridTwo: "",
    social: [],
    info: {},
    grid: [],
    gridTwo: [],
  });

  const fetchFooterData = async () => {
    try {
      const response = await getFooter();
      console.log("footer:", response);
      setFooterData(response);
    } catch (error) {
      console.error("Error fetching footer data", error);
    }
  };
  useEffect(() => {
    fetchFooterData();
  }, []);

  return (
    <>
      <footer className="relative bg-blueGray-200 mt-20 pt-8 pb-6">
        <div
          className="bottom-auto top-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20 h-20"
          style={{ transform: "translateZ(0)" }}
        >
          <svg
            className="absolute bottom-0 overflow-hidden"
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="none"
            version="1.1"
            viewBox="0 0 2560 100"
            x="0"
            y="0"
          >
            <polygon
              className="text-blueGray-200 fill-current"
              points="2560 0 2560 100 0 100"
            ></polygon>
          </svg>
        </div>
        <div className="container mx-auto px-4">
          <div className="flex flex-wrap text-center lg:text-left">
            <div className="w-full lg:w-6/12 px-4">
              <h4 className="text-3xl font-semibold">Let's keep in touch!</h4>
              <h5 className="text-lg mt-0 mb-2 text-blueGray-600">
                Find us on any of these platforms, we respond 1-2 business days.
              </h5>
              {footerData?.social?.map((icon, index) => (
                <button
                  key={index}
                  className="bg-white text-lightBlue-400 shadow-lg font-normal h-10 w-10 items-center justify-center align-center rounded-full outline-none focus:outline-none mr-2"
                  type="button"
                >
                  <i className={icon}></i>
                </button>
              ))}
            </div>
            <div className="w-full lg:w-6/12 px-4">
              <div className="flex flex-wrap items-top mb-6">
                <div className="w-full lg:w-4/12 px-4 ml-auto">
                  <span className="block uppercase text-blueGray-500 text-sm font-semibold mb-2">
                    {footerData?.titleGrid}
                  </span>
                  <ul className="list-unstyled">
                    {footerData?.grid?.map((item, index) => (
                      <li key={index}>
                        <a
                          className="text-blueGray-600 hover:text-blueGray-800 font-semibold block pb-2 text-sm"
                          href={item?.url}
                        >
                          {item?.name}
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
                <div className="w-full lg:w-4/12 px-4">
                  <span className="block uppercase text-blueGray-500 text-sm font-semibold mb-2">
                    {footerData?.titleGridTwo}
                  </span>
                  <ul className="list-unstyled">
                    {footerData?.gridTwo?.map((item, index) => (
                      <li key={index}>
                        <a
                          className="text-blueGray-600 hover:text-blueGray-800 font-semibold block pb-2 text-sm"
                          href={item?.url}
                        >
                          {item?.name}
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <hr className="my-6 border-blueGray-300" />
          <div className="flex flex-wrap items-center md:justify-between justify-center">
            <div className="w-full md:w-4/12 px-4 mx-auto text-center">
              <div className="text-sm text-blueGray-500 font-semibold py-1">
                {new Date().getFullYear()} {footerData?.info?.copyright}
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
