import React, { useEffect, useState } from "react";
import { getLogoAndFavicon } from "../api/logoSetting";

const SEOComponent = () => {
  const [settings, setSettings] = useState({});

  useEffect(() => {
    const fetchSettings = async () => {
      try {
        const response = await getLogoAndFavicon();
        setSettings(response);
      } catch (error) {
        console.error("Error fetching settings", error);
      }
    };

    fetchSettings();
  }, []);

  useEffect(() => {
    if (settings) {
      document.title = "RoyalKatd";

      document
        .querySelector('link[rel="icon"]')
        .setAttribute("href", settings?.favicon?.url);
    }
  }, [settings]);

  return null;
};

export default SEOComponent;
